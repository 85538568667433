<template>
  <div class="title-bar">
    <div class="title-bar__bar">
      <h1 v-if="$route.meta.showTitle" class="title-bar__title t-display">
        {{ pageTitle }}
      </h1>
      <div v-if="$slots.default" class="title-bar__secondary">
        <slot />
      </div>
      <div class="spacer"></div>
      <div v-if="info.label && info.component" class="title-bar__actions">
        <button
          class="title-bar__action-info"
          @click="toggleInfo(true)">
          {{ info.label }}
          <help-circle-icon />
        </button>
      </div>
    </div>
    <div class="title-bar__overlay" v-if="info.label && info.component">
      <transition name="slide-fade-bottom">
        <panel v-if="showInfo" class="title-bar__info" @dismiss="toggleInfo(false)">
          <component :is="info.component" v-bind="info.componentProps" />
        </panel>
      </transition>
    </div>
  </div>
</template>
<script>
import { XIcon, HelpCircleIcon } from 'vue-feather-icons';
import { mapState } from 'vuex';
import Panel from '../../components/Panel.vue';

export default {
  name: 'TitleBar',
  components: {
    Panel,
    HelpCircleIcon,
    XIcon,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showInfo: false,
  }),
  computed: {
    ...mapState({
      pageTitle: 'pageTitle',
    }),
  },
  methods: {
    closeOnEsc() {
      this.showInfo = false;
    },
    toggleInfo(force) {
      this.showInfo = force !== undefined ? force : !this.showInfo;
    },
  },
  created() {
    window.document.addEventListener('keydown', this.closeOnEsc);
  },
  beforeDestroy() {
    window.document.removeEventListener('keydown', this.closeOnEsc);
  },
};
</script>
<style lang="postcss" scoped>
.title-bar {
  padding: var(--space-1) 0;
  position: sticky;
  top: var(--tt-header-height);
  background-color: var(--tt-page-background-color);
  z-index: 1;
}
.title-bar__bar {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.title-bar__title {
  padding-right: var(--space-1);
}
.title-bar__action-info {
  font-size: var(--fs-interface);
  align-items: center;
  display: inline-flex;
  svg {
    margin-left: 4px;
    width: calc( 1.2 * var(--fs-interface));
    height: calc( 1.2 * var(--fs-interface));
  }
}
.title-bar__overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.spacer {
  flex: 1;
}
@media screen and (max-width: 768px) {
  .title-bar {
    top: var(--height-2);
  }
  .title-bar__bar {
    flex-direction: column;
  }
}
</style>
