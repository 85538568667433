<template>
  <div class="footer">
    <div class="footer-container">
      <img class="footer-white" src="../../assets/footer-white.svg" alt="">
      <img class="footer-black" src="../../assets/footer-black.svg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppFooter',
};
</script>
<style lang="postcss">
  .dark-mode {
    .footer-white {
      display: none !important;
    }
    .footer-black {
      display: initial !important;
    }
  }
</style>
<style lang="postcss" scoped>
  .footer-white {
    display: initial;
  }
  .footer-black {
    display: none;
  }
  .footer {
    padding-top: var(--space-3);
    margin: var(--space-3) calc( -1 * var(--space-3)) 0;
  }
  .footer-container {
    max-width: 1440px;
    margin: 0 auto;
    img {
      margin-bottom: -12px;
    }
  }
  .footer-icons {
    display: flex;
    align-items: center;
    .spacer {
      flex: 1;
    }
    img {
      padding: 20px;
    }
  }
</style>
