<template>
  <div class="grid">
    <div class="col-third pr-2">
      <p>
        <router-link class="t-label guide-link" :to="{ name: 'guide', query: { id: '8' }}">All Technologies</router-link>
        <br>
        This is a complete snapshot of all of the technologies we are tracking in the MIT Tech Tracker at any given point in time. It is a living database so new technologies and embedded technologies are being added all the time. Technologies are documented in the order that users filter, then the technology name can be selected to highlight and identify more information. Further, users can simply search for technologies that might be relevant to them in the search box.
      </p>
    </div>
    <div class="col-third pr-2">
      <p>
        <span class="t-label">How</span>
        <br>
        The tool allows the user to understand the technologies through various lenses. Users can filter by momentum, real estate product or process, by real estate strategy or by development stage. This helps users to facilitate searches for particular research, market and policy making uses. Users can then investigate individual technologies on technology profile pages on display in MIT All Techs View or investigate further by going directly into each technology’s profile for further analysis.
      </p>
    </div>
    <div class="col-third">
      <p>
        <span class="t-label">Why</span>
        <br>
        While there are numerous lists of emerging technologies in the market, there is no list that endeavors to aggregate all technologies that empower real estate in one place. The MIT Tech Tracker provides this knowledge in one place to help facilitate the creators, investors, users and participants of our cities and the built environment.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DirectoryInfo',
};
</script>
<style lang="postcss" scoped>
.t-label {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .grid {
    flex-direction: column;
    > * {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
}
</style>
