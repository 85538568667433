import Vue from 'vue';
import Router from 'vue-router';
import Admin from './views/Admin.vue';
import Guide from './views/Guide.vue';
import Experience from './views/Experience.vue';
import AboutIntro from './views/AboutIntro.vue';
import Login from './views/Login.vue';
import Ranking from './views/Ranking.vue';
import Settings from './views/Settings.vue';
import TechDirectory from './views/TechDirectory.vue';

Vue.use(Router);
const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    // Do not adjust scroll between list/detail views on same parent view
    if (to.name === from.name) {
      return undefined;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        title: 'Admin',
        showTitle: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Log In',
        showTitle: true,
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        title: 'Settings',
      },
    },
    {
      path: '/guide',
      name: 'guide',
      component: Guide,
      meta: {
        title: 'Guide',
      },
    },
    {
      path: '/experience',
      name: 'experience',
      component: Experience,
      meta: {
        title: 'Experience',
      },
    },
    {
      path: '/ranking',
      name: 'ranking',
      component: Ranking,
      meta: {
        title: 'Top 25 Techs',
        showTitle: true,
      },
    },
    {
      path: '/all-technologies',
      name: 'all',
      component: TechDirectory,
      meta: {
        title: 'MIT All Techs',
        showTitle: true,
      },
    },
    {
      path: '/intro',
      name: 'intro',
      component: AboutIntro,
      meta: {
        title: 'Introduction',
      },
    },
  ],
});

router.addRoute({
  path: '/',
  name: 'home',
  component: window.localStorage.getItem('intro_seen') ? Ranking : AboutIntro,
  meta: {
    title: window.localStorage.getItem('intro_seen') ? 'Ranking' : 'Introduction',
  },
});

export default router;
