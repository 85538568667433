<template>
  <nav class="intro__nav" :class="{ 'is-visible': open }">
    <div class="toggles">
      <button @click="$emit('update:open', !open)">
        <span class="toggle__open"><MenuIcon /></span>
        <span class="toggle__close"><XIcon /></span>
      </button>
    </div>
    <ul>
      <li>
        <a href="#" v-scroll-to="{ el: '#why', offset: -50 }">
          Why we do it
        </a>
      </li>
      <li>
        <a href="#" v-scroll-to="{ el: '#how', offset: -50 }">
          How we do it
        </a>
      </li>
      <li>
        <a href="#" v-scroll-to="{ el: '#what', offset: -50 }">
          What you get
        </a>
      </li>
      <li>
        <router-link :to="{ name: 'ranking' }">
          Track Technologies
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { MenuIcon, XIcon } from 'vue-feather-icons';
// import throttle from 'lodash/throttle';

export default {
  name: 'IntroNavigation',
  components: {
    XIcon,
    MenuIcon,
  },
  props: {
    open: Boolean,
  },
  created() {
    // window.addEventListener('scroll', throttle(() => {
    //   this.visible = false;
    // }, 1000));
  },
};
</script>
<style lang="postcss" scoped>

.intro__nav.is-visible {
  .toggle__open {
    display: none;
  }
  .toggle__close {
    display: inline-block;
  }
  ul {
    display: block;
  }
}
.intro__nav:not(.is-visible) {
  .toggle__open {
    display: inline-block;
  }
  .toggle__close {
    display: none;
  }
  ul {
    display: none;
  }
}
.toggles {
  text-align: right;
}

.intro__nav {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index:  1;

  li a {
    display: block;
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 1000px;
    background-color: var(--tt-page-text-color);
    color: var(--tt-page-background-color);
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }

    &.cta {
      background-color: var(--color-black);
      color: var(--color-white);
      &:hover {
        background-color: var(--color-gold);
        color: var(--tt-page-background-color);
      }
    }
  }
}
@media screen and (max-width: 950px) {
  .intro__nav li a {
    font-size: 12px;
    padding: 8px 14px;
    margin: 12px 0;
  }
}
</style>
