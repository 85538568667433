<template>
  <div class="covers">
    <div class="cover">
      <div class="cover__title">
        10<br>
        Technologies<br>
        STEM<br>
        <span class="date">2021</span>
      </div>
    </div>
    <div class="cover">
      <div class="cover__title">
        10<br>
        Technologies<br>
        HEALTH<br>
        <span class="date">2021</span>
      </div>
    </div>
    <div class="cover">
      <div class="cover__title">
        10<br>
        Technologies<br>
        FINANCE<br>
        <span class="date">2021</span>
      </div>
    </div>
    <div class="cover">
      <div class="cover__title">
        10<br>
        Technologies<br>
        ROBOTICS<br>
        <span class="date">2021</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CoverItems',
};
</script>
<style lang="postcss" scoped>
.covers {
  width: 100vw;
  white-space: nowrap;
}
.cover {
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--font-size-header);
  letter-spacing: -1px;
  position: relative;
  background-color: white;
  margin: 20px;
  padding: 40px 20px 180px;
  display: inline-block;
  border: 1px solid black;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
    top: -6px;
    left: -6px;
  }
}
.cover__title {
  font-weight: bold;
  line-height: 1;
}
.date {
  font-weight: normal;
}
</style>
