import Vue from 'vue';
import Vuex from 'vuex';
import apiService from './apiService';

Vue.use(Vuex);

// Set up cached data
// TODO: invalidate after a certain # of days
const LScache = {
  setItem(name, collection) {
    try {
      const storageItem = {
        collection,
        datetime: new Date(),
      };
      localStorage.setItem(name, JSON.stringify(storageItem));
      return collection;
    } catch (error) {
      return null;
    }
  },
  getItem(name) {
    // check if still valid
    try {
      return JSON.parse(localStorage.getItem(name)).collection;
    } catch (error) {
      return null;
    }
  },
};

const classifyType = (tech) => {
  if (tech.is_prod) {
    return 'product';
  }
  if (tech.is_proc) {
    return 'process';
  }
  return 'use';
};

const getCategories = (tech) => {
  const cat = [];
  if (tech.sustainability !== 0) {
    cat.push('Sustainability');
  }
  if (tech.health_wellness !== 0) {
    cat.push('Health Wellness');
  }
  if (tech.data_analytics !== 0) {
    cat.push('Data Analytics');
  }
  if (tech.automation !== 0) {
    cat.push('Automation');
  }
  if (tech.hybrid_office !== 0) {
    cat.push('Hybrid Work');
  }
  return cat;
};

export default new Vuex.Store({
  state: {
    authToken: null,
    pageTitle: 'MIT Tech Tracker',
    footerOverlayComponent: null,
    technologies: [],
    intro: true,
    seen: {
      awareness: false,
      ranking: false,
      directory: false,
    },
    charts: {
      awareness: {
        data: [],
        loading: false,
      },
    },
  },
  getters: {
    getTechById: (state) => (id) => state.technologies.find((t) => t.id === parseInt(id, 10)) || null,
  },
  mutations: {
    SET_AUTH_TOKEN(state, token) {
      Vue.set(state, 'authToken', token);
    },
    SET_SEEN(state, attr) {
      Vue.set(state.seen, attr, true);
    },
    SET_PAGE_TITLE(state, title) {
      Vue.set(state, 'pageTitle', title);
    },
    TOGGLE_INTRO(state, toggle) {
      Vue.set(state, 'intro', toggle);
    },
    SET_FOOTER_OVERLAY(state, componentAndProps) {
      Vue.set(state, 'footerOverlayComponent', componentAndProps);
    },
    SET_TECHNOLOGIES(state, technologies) {
      Vue.set(state, 'technologies', technologies);
    },
    SET_TECHNOLOGY(state, technology) {
      const techs = state.technologies;
      const tech = state.technologies.find((t) => t.id === technology.id);
      const techindex = techs.indexOf(tech);
      techs[techindex] = { ...tech, ...technology };
      Vue.set(state, 'technologies', [...techs]);
    },
  },
  actions: {
    clearCache() {
      window.localStorage.clear();
      return fetch(`${apiService.API_ENDPOINT}/cache-clear/`);
    },
    dismissFooterOverlay(context) {
      context.commit('SET_FOOTER_OVERLAY', null);
    },
    fetchTechnologies(context) {
      // const cachedTechs = LScache.getItem('technologies');
      const cachedTechs = undefined;
      if (cachedTechs) {
        context.commit('SET_TECHNOLOGIES', cachedTechs);
      } else {
        // TODO: use const
        fetch('/technologies.json').then((r) => r.json()).then((data) => {
          const techs = data.map((t) => ({
            ...t,
            perf_dspace: null,
            type: classifyType(t),
            machine_name: t.name.toLowerCase().split(' ').join('_'),
            categories: getCategories(t),
            wiki_link: t.wiki_link !== 'None' ? t.wiki_link : null,
          }));
          context.commit('SET_TECHNOLOGIES', techs);
          LScache.setItem('technologies', techs);
        });
      }
    },
    setPageTitle(context, title) {
      window.document.title = `MIT Tech Tracker | ${title}`;
      context.commit('SET_PAGE_TITLE', title);
    },
    setFooterOverlay(context, { component, props }) {
      context.commit('SET_FOOTER_OVERLAY', { component, props });
    },
    setSeen(context, attr) {
      context.commit('SET_SEEN', attr);
    },
    toggleIntro(context, toggle) {
      context.commit('TOGGLE_INTRO', toggle);
    },
  },
});
