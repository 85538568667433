<template>
  <div class="grid">
    <div class="col-third pr-2">
      <p>
        <router-link class="t-label guide-link" :to="{ name: 'guide', query: { id: '7' }}">Top Technologies</router-link>
        <br>
        We chart the aggregate awareness of technologies over time and rank them using a “live” algorithm to create the Top 25 Techs and the MIT All Techs charts. The list utilizes academic, venture and social awareness data to understand how technologies are changing through different stages in their development.
      </p>
    </div>
    <div class="col-third pr-2">
      <p>
        <span class="t-label">How</span>
        <br>
        Technologies develop awareness at different stages from distinct communities of inventors, innovators and social actors. These signals of citation, investment and sharing are key to measuring the rising, pacing and cooling momentum of a technology. Our algorithm measures the weighted awareness of our pool of tracked technologies and then ranks them over different time periods. The user can then view the Top 25 Techs by different technology categories: Momentum, Type, Strategy, and Stage.
      </p>
    </div>
    <div class="col-third">
      <p>
        <span class="t-label">Why</span>
        <br>
        Our technology lists help us to understand and decipher relevant information to make informed strategic decisions. However, they also teach us about emerging technologies in the lab and the market, as well as what remains continuously relevant in our life.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RankingInfo',
};
</script>
<style lang="postcss" scoped>
.t-label {
  text-decoration: underline;
  /* display: block; */
}
@media screen and (max-width: 768px) {
  .grid {
    flex-direction: column;
    > * {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
}
</style>
