<template>
  <div class="generated-dot">
    <div class="generated-dot__container" :class="animationClass" :style="style"></div>
  </div>
</template>
<script>
let index = 0;

const colors = [
  '#782aff',
  '#faab1a',
  '#d52c39',
  '#009761',
  '#782aff',
];
export default {
  name: 'GeneratedDot',
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 0,
    },
    xBoundary: {
      type: Number,
      default: 0,
    },
    yBoundary: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    animationClass() {
      return ['pulse-1', 'pulse-2', 'pulse-3', 'pulse-4'][Math.floor(Math.random() * 4)];
    },
    style() {
      if (!colors[index]) {
        index = 0;
      }
      const color = colors[index];
      index += 1;
      const randSizeSeed = Math.random() * 7;
      return {
        backgroundColor: color,
        top: `${this.xBoundary + ((this.x - this.xBoundary) + (Math.random() * 9))}%`,
        left: `${this.y + (Math.random() * 9)}%`,
        width: `${this.size + randSizeSeed}vw`,
        height: `${this.size + randSizeSeed}vw`,
      };
    },
  },
};
</script>
<style lang="postcss" scoped>
.generated-dot__container {
  position: absolute;
  border-radius: 100%;
  z-index: -1;
}
.pulse-1 {
   animation: pulse-1 12s infinite;
}
.pulse-2 {
   animation: pulse-2 12s infinite;
}
.pulse-3 {
   animation: pulse-3 12s infinite;
}
.pulse-4 {
   animation: pulse-4 12s infinite;
}

@keyframes pulse-1 {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(25%, 40%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes pulse-2 {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(-5%, 20%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes pulse-3 {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(-25%, 10%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes pulse-4 {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(-5%, -10%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
</style>
