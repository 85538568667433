<template>
  <button class="intro-button" :class="{ large }" v-on="$listeners">
    <div class="button-text">
      <slot />
    </div>
    <div class="button-arrow">
      <ArrowRightIcon v-if="direction === 'right'" />
      <ArrowDownIcon v-if="direction === 'down'" />
    </div>
  </button>
</template>
<script>
import { ArrowRightIcon, ArrowDownIcon } from 'vue-feather-icons';

export default {
  name: 'IntroButton',
  components: {
    ArrowRightIcon,
    ArrowDownIcon,
  },
  props: {
    direction: {
      type: String,
      default: 'down',
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="postcss" scoped>
  .intro-button {
    color: var(--color-white);
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    background-color: var(--color-black);
    &.large {
      width: 260px;
      height: 260px;
      font-size: 24px;
    }
    .button-arrow {
      display: none;
    }
    .button-text {
      max-width: 70%;
    }
    >>> svg {
      width: 100px;
      height: 100px;
    }
    &:hover {
      .button-arrow {
        display: initial;
      }
      .button-text {
        display: none;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .intro-button {
      font-size: 14px;
      width: 120px;
      height: 120px;
    }
  }
</style>
