<template>
  <section class="panel" v-bind="$attrs">
    <button class="icon-button dismiss" @click="$emit('dismiss')">
      <x-icon />
    </button>
    <slot>
    </slot>
  </section>
</template>
<script>
import { XIcon } from 'vue-feather-icons';

export default {
  name: 'Panel',
  components: {
    XIcon,
  },
};
</script>
<style lang="postcss" scoped>
.panel {
  border-top: 1px solid var(--tt-page-text-color);
  background: var(--tt-page-background-color);
  padding: 2rem;
  z-index: var(--z-overlay2);
}
.dismiss {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
