<template>
  <div class="filters-description">
    <span class="t-label">CURRENT FILTER:</span> {{ filterDescription }}.
  </div>
</template>
<script>

const filterLookup = {
  datatype: {
    academic: 'academic',
    social: 'social',
    venture: 'venture',
    '': 'all data types',
  },
  stage: {
    'in the lab': 'in the lab',
    'in the market': 'in the market',
    'in our life': 'in our life',
    '': 'all stages',
  },
  category: {
    sustainability: 'sustainability',
    'health wellness': 'health and wellness',
    'data analytics': 'data analytics',
    automation: 'automation',
    'hybrid office': 'hybrid work',
    '': 'all strategies',
  },
  techtype: {
    product: 'product',
    process: 'process',
    '': 'all technologies',
  },
  momentum: {
    'rising fast': 'rising fast',
    'cooling down': 'cooling down',
    'keeping pace': 'keeping pace',
    '': 'all momentum',
  },
  timeframe: {
    '1y': '1 year',
    '5y': '5 years',
    '10y': '10 years',
    all_time: 'all time',
  },
};

export default {
  name: 'FiltersDescription',
  props: {
    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    filterDescription() {
      return `
        By ${filterLookup.techtype[this.appliedFilters.techtype]}, ${filterLookup.category[this.appliedFilters.category]}, ${filterLookup.stage[this.appliedFilters.stage]}, and ${filterLookup.momentum[this.appliedFilters.momentum]}`;
    },
  },
};
</script>
