<template>
  <div class="animated-logo__entry">
    <div class="animated-logo-wrapper">
      <AnimatedLogo :collapsed="collapsed" :stacked="stacked" />
      <div class="animated-logo__entry__buttons">
        <template v-if="introButtonsVisible" >
          <button class="button" @click="$emit('hide')">About Tech Tracker</button>
          <router-link tag="button" class="button" :to="{ name: 'ranking' }">
            Track Technologies
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import AnimatedLogo from '../../components/icons/AnimatedLogo.vue';

export default {
  name: 'IntroEntry',
  components: {
    AnimatedLogo,
  },
  data() {
    return {
      stacked: true,
      collapsed: true,
      introButtonsVisible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.stacked = false;
    }, 1000);
    setTimeout(() => {
      this.collapsed = false;
    }, 2000);
    setTimeout(() => {
      this.introButtonsVisible = true;
    }, 3000);
  },
};
</script>
<style lang="postcss" scoped>
.animated-logo__entry {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  >>> .icon-logo svg {
    width: 50vw;
    max-width: 500px;
    height: auto;
  }
}
.animated-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.animated-logo__entry__buttons {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  width: 76%;
  margin-top: 20px;
  > *  {
    width: 100%;
    & + * {
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .animated-logo-wrapper {
    align-items: flex-start;
    .icon-logo {
      margin: 0 auto;
    }
  }
}
</style>
