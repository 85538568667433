<template>
  <div class="scroll-aware" :class="`transition-${state}`">
    <slot />
  </div>
</template>
<script>
import throttle from 'lodash/throttle';

export default {
  name: 'scrollAware',
  data: () => ({
    uuid: null,
    state: undefined,
    cssClass: '',
  }),
  mounted() {
    this.uuid = Math.random() * 1000;
    window.addEventListener('scroll', throttle(() => {
      const height = document.documentElement.clientHeight;
      const offset = this.$el.getBoundingClientRect();
      if (offset.top < 0 && offset.bottom < 0) {
        this.state = 'below';
        this.$emit('below', offset);
        return;
      }
      if (offset.top >= 0 && offset.bottom >= 0 && offset.top > height) {
        this.state = 'above';
        this.$emit('above', offset);
        return;
      }
      this.state = 'inview';
      this.$emit('in-view', offset);
    }, 200));
  },
};
</script>
