<template>
  <header class="header" :class="{ isOpen }">
    <div class="header--mobile">
      <router-link class="header__logo-link" :to="{ name: 'intro' }">
        <AnimatedLogo />
      </router-link>
      <button class="menu-button" @click="isOpen = !isOpen">
        MENU
      </button>
    </div>
    <div class="grid pt-2 pb-2 header__navigation">
      <div class="col2 col--logo">
        <router-link class="header__logo-link" :to="{ name: 'intro' }">
          <AnimatedLogo />
        </router-link>
      </div>
      <div class="col2">
        <ul class="nav-list">
          <li class="t-label--small">Charts</li>
          <li>
            <router-link class="nav-link" :to="{name: 'ranking'}">Top 25 Techs</router-link>
          </li>
          <li>
            <router-link class="nav-link" :to="{name: 'all'}">MIT All Techs</router-link>
          </li>
        </ul>
      </div>
      <div class="col2">
        <ul class="nav-list">
          <li class="t-label--small">About</li>
          <li>
            <router-link class="nav-link" :to="{name: 'experience'}">Experience</router-link>
          </li>
          <li>
            <router-link class="nav-link" :to="{name: 'guide'}">Guide</router-link>
          </li>
        </ul>
      </div>
      <div class="mode-toggle-container">
        <label for="mode-toggle" class="mode-toggle" :class="{ active: darkmode }">
          <input id="mode-toggle" type="checkbox" v-model="darkmode">
          <lightbulb-icon class="mode-toggle__bulb" />
        </label>
      </div>
    </div>
  </header>
</template>
<script>
import AnimatedLogo from '../../components/icons/AnimatedLogo.vue';
import LightbulbIcon from '../../components/icons/LightbulbIcon.vue';

export default {
  name: 'AppHeader',
  components: {
    LightbulbIcon,
    AnimatedLogo,
  },
  data() {
    return {
      isOpen: false,
      darkmode: false,
    };
  },
  watch: {
    darkmode() {
      window.document.body.classList.toggle('dark-mode');
    },
  },
};
</script>
<style lang="postcss" scoped>
header {
  height: var(--tt-header-height);
  overflow: hidden;
  margin-bottom: var(--space-2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 var(--space-3);
  background-color: var(--tt-page-background-color);
  border-bottom: 2px solid var(--tt-page-text-color);
  z-index: var(--z-overlay1);
}
.header__logo-link {
  color: var(--tt-page-text-color);
}
.nav-list {
  /* padding-left: var(--space-1); */
  /* border-left: 1px solid var(--tt-page-text-color); */
  height: 100%;
}
.nav-list--slot {
  border-left: none;
  padding: 0;
  > * {
    border-left: 1px solid var(--tt-page-text-color);
    padding-left: var(--space-1);
    height: 100%;
  }
}
.nav-link {
  color: var(--tt-page-text-color);
  display: inline-block;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    /* color: var(--tt-page-text-color); */
  }
}

.logo-icon {
    position: absolute;
    top: 30px;
    right: 40px;
}

.header--mobile {
  display: none;
}

.header__navigation {
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  header {
    height: var(--height-2);
  }
  .header--mobile {
    position: relative;
    padding: var(--space-3) 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: var(--z-overlay);
    >>> .icon-logo svg {
      height: 40px;
    }
  }
  .header__navigation {
    display: none;
  }
  .header.isOpen .header__navigation {
    display: block;
    position: fixed;
    top: var(--height-2);
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 30px;
    background: var(--tt-page-background-color);
    z-index: var(--z-overlay3);
    .col--logo,
    .col--filters {
      display: none;
    }
    .nav-list {
      border-left: none;
      margin-bottom: var(--space-3);
      li {
        padding: calc(var(--space-1)/2) 0;
      }
    }
  }
}

.mode-toggle-container {
  display: flex;
  align-items: flex-end;
}
.mode-toggle {
  cursor: pointer;

  input {
    visibility: hidden;
    display: inline-block;
    overflow: hidden;
    width: 0;
    height: 0;
  }
  .mode-toggle__bulb {
    width: 48px;
    height: 48px;
  }
  &.active >>> g {
    fill: #fff;
  }
}
</style>
