<template>
  <div class="filter-wrapper">
    <form class="filters" @submit.prevent="applyFilters">
      <h3 class="t-label filters__title">
        FILTER <filter-icon size="14" />
      </h3>
      <template v-if="includeDataFilters">
        <h4 class="filters__subtitle">Select Data</h4>
        <template v-for="(f, i) in dataFilters">
          <div class="filter" :key="`data${i}${f.key}`">
            <label :for="`filter-${f.key}-${i}`" class="filter-title">
              <span class="filter-title__name">{{ f.label }}</span>
            </label>
            <select :id="`filter-${f.key}-${i}`" @change="($event) => setFilter(f.key, $event.target.value)">
              <option v-for="(fv, ii) in f.options" :key="`${f.key}${ii}`" :value="fv.value" :selected="appliedFilters[f.key] === fv.value">
                {{ fv.name }}
              </option>
            </select>
          </div>
        </template>
      </template>

      <h4 class="filters__subtitle">Select Technologies</h4>
      <template v-for="(f, i) in otherFilters">
        <div class="filter" :key="`data${i}${f.key}`">
          <label :for="`filter-${f.key}-${i}`" class="filter-title">
            <span class="filter-title__name">{{ f.label }}</span>
          </label>
          <select :id="`filter-${f.key}-${i}`" @change="($event) => setFilter(f.key, $event.target.value)">
            <option v-for="(fv, ii) in f.options" :key="`${f.key}${ii}`" :value="fv.value" :selected="appliedFilters[f.key] === fv.value">
              {{ fv.name }}
            </option>
          </select>
        </div>
      </template>

      <button v-if="showReset" class="button" @click="$emit('change', defaultFilters)">
        Reset
      </button>
    </form>
  </div>
</template>
<script>
import { FilterIcon } from 'vue-feather-icons';

export default {
  name: 'TechFilters',
  components: {
    FilterIcon,
  },
  props: {
    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
    includeDataFilters: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showFilters: false,
    dataFilters: [
      {
        label: 'Awareness Type',
        key: 'datatype',
        options: [
          {
            name: 'Academic',
            value: 'academic',
          },
          {
            name: 'Social',
            value: 'social',
          },
          {
            name: 'Venture',
            value: 'venture',
          },
          {
            name: 'All Awareness Types',
            value: '',
          },
        ],
      },
      {
        label: 'Timeframe',
        key: 'timeframe',
        options: [
          {
            name: '1 Year',
            value: '1y',
          },
          {
            name: '5 Years',
            value: '5y',
          },
          {
            name: '10 Years',
            value: '10y',
          },
          {
            name: 'All Time',
            value: 'all_time',
          },
        ],
      },
    ],
    otherFilters: [
      {
        label: 'Technology Type',
        key: 'techtype',
        options: [
          {
            name: 'Products',
            value: 'product',
          },
          {
            name: 'Processes',
            value: 'process',
          },
          {
            name: 'All Technologies',
            value: '',
          },
        ],
      },
      {
        label: 'Strategy',
        key: 'category',
        options: [
          {
            name: 'Sustainability',
            value: 'sustainability',
          },
          {
            name: 'Health and Wellness',
            value: 'health wellness',
          },
          {
            name: 'Data Analytics',
            value: 'data analytics',
          },
          {
            name: 'Automation',
            value: 'automation',
          },
          {
            name: 'Hybrid Work',
            value: 'hybrid office',
          },
          {
            name: 'All Strategies',
            value: '',
          },
        ],
      },
      {
        label: 'Stage',
        key: 'stage',
        options: [
          {
            name: 'In the Lab',
            value: 'in the lab',
          },
          {
            name: 'In the Market',
            value: 'in the market',
          },
          {
            name: 'In our Life',
            value: 'in our life',
          },
          {
            name: 'All Stages',
            value: '',
          },
        ],
      },
      {
        label: 'Momentum',
        key: 'momentum',
        options: [
          {
            name: 'Rising Fast',
            value: 'rising fast',
          },
          {
            name: 'Keeping Pace',
            value: 'keeping pace',
          },
          {
            name: 'Cooling Down',
            value: 'cooling down',
          },
          {
            name: 'All Momentum',
            value: '',
          },
        ],
      },
    ],
  }),
  computed: {
    defaultFilters() {
      const filters = {
        category: '',
        momentum: '',
        stage: '',
        techtype: '',
      };
      if (this.includeDataFilters) {
        filters.datatype = '';
        filters.timeframe = 'all_time';
      }
      return {
        ...filters,
      };
    },
    showReset() {
      return JSON.stringify(this.defaultFilters) !== JSON.stringify(this.appliedFilters);
    },
  },
  methods: {
    setFilter(labelKey, filterKey) {
      const appliedFilters = { ...this.appliedFilters, [labelKey]: filterKey };
      this.$emit('change', appliedFilters);
    },
  },
};
</script>
<style lang="postcss" scoped>
.filter {
  .filter-title {
    display: flex;
    font-size: var(--type-interface);
    .filter-title__name {
      padding: 4px 4px 0 4px;
      flex: 1;
    }
    .filter-title__link {
      padding: 4px;
      &:hover {
        background-color: rgba(0,0,0,0.05);
      }
    }
  }
  select {
    display: block;
    width: 100%;
    font-size: var(--type-base);
    color: var(--tt-page-text-color);
    background-color: rgba(0,0,0,0.05);
    outline: none;
    border: none;
    padding: 4px;
  }
}
.filter--submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.page-filters {
  border-top: 1px solid var(--tt-page-text-color);
  background: var(--tt-page-background-color);
  z-index: var(--z-overlay2);
  padding: 2rem;
  z-index: var(--z-overlay2);
  transition: all 250ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}
.update-chart-button {
  padding: 0.25em 0;
  margin: 3rem 2rem 0;
}

.selection {
  margin-bottom: calc(var(--space-1)/2);
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.active {
    color: var(--tt-page-text-color);
  }
}

.filters__title {
  font-weight: bold;
  height: var(--tt-table-header-height);
  padding: var(--space-2) var(--space-1) var(--space-1);
  border-bottom: 2px solid var(--tt-page-text-color);
}
.filters__subtitle {
  font-weight: bold;
  font-size: var(--type-small);
  text-transform: uppercase;
  margin-top: 20px;
}

.button {
  margin-top: 10px;
}
</style>
