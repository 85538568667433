<template>
  <div class="app-main-wrapper">
    <app-header />
    <div clas="app-main">
      <form action="#" class="login-form" @submit.prevent="login">
        <div v-if="errorMessage" class="error"> {{errorMessage}} </div>
        <div class="form-field">
          <label for="email">Email</label>
          <input v-model="email" id="email" type="email">
        </div>
        <div class="form-field">
          <label for="password">Password</label>
          <input v-model="password" id="password" type="password">
        </div>
        <div class="form-field">
          <button class="button" :disabled="submitting">
            <template v-if="!submitting">Log in</template>
            <template v-else>...</template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import AppHeader from './partials/AppHeader.vue';
import apiService from '../apiService';

export default {
  name: 'Login',
  components: {
    AppHeader,
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      submitting: false,
    };
  },
  methods: {
    login() {
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('password', this.password);
      fetch(`${apiService.API_BASE}/user/token/`, {
        method: 'POST',
        mode: 'cors',
        body: formData,
      }).then((response) => response.json())
        .then((data) => {
          if (data.token) {
            this.$store.commit('SET_AUTH_TOKEN', data.token);
            this.$router.push({ name: 'admin' });
          }
          if (data.message) {
            this.errorMessage = data.message;
          }
        });
    },
  },
};
</script>
<style lang="postcss" scoped>
  .login-form {
    margin: 40px auto;
    max-width: 300px;
  }
  .form-field {
    margin-bottom: 10px;
  }
  .form-field label {
    display: block;
  }
  .form-field input {
    width: 100%;
  }
</style>
