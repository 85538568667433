<template>
  <div class="action-logo-block">
    <img src="./../../assets/action-logo-block.svg" alt="" class="background-image">
    <h1 class="t-headline">
      and to turn bytes into insights <br> with the...
    </h1>
    <div class="logo-action-container">
      <div class="logo-container">
        <AnimatedLogo :collapsed="collapsed" :stacked="stacked" />
      </div>
      <div v-if="$slots.default" class="actions">
        <template v-if="showActions">
          <slot />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import AnimatedLogo from '../../components/icons/AnimatedLogo.vue';

export default {
  name: 'ActionLogoBlock',
  components: {
    AnimatedLogo,
  },
  data() {
    return {
      collapsed: true,
      stacked: true,
      showActions: false,
    };
  },
  methods: {
    expand() {
      setTimeout(() => {
        this.stacked = false;
      }, 500);
      setTimeout(() => {
        this.collapsed = false;
      }, 1000);
      setTimeout(() => {
        this.showActions = true;
      }, 1500);
    },
  },
};
</script>
<style lang="postcss" scoped>
.action-logo-block {
  --logosize: 300px;
  position: relative;
  min-height: 50vh;
  margin: 20vh 0;
  z-index: 1;
}
.t-headline {
  margin-bottom: 80px;
}
.logo-container {
  display: flex;
  justify-content: center;
}
.actions {
  margin-right: calc((100% - 560px) / 2);
  width: 450px;
  min-height: 180px;
  margin-left: auto;
}
.background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  transform: translate(-55%, -60%);
  z-index: -1;
  max-width: none;
}
@media screen and (max-width: 950px) {
  .action-logo-block {
    --logosize: 150px;
  }
  .t-headline {
    margin-bottom: 40px;
  }
  .actions {
    width: 100%;
    margin-right: 0;
  }
}
</style>
