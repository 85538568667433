import { render, staticRenderFns } from "./TechDetail.vue?vue&type=template&id=48560e17&scoped=true&"
import script from "./TechDetail.vue?vue&type=script&lang=js&"
export * from "./TechDetail.vue?vue&type=script&lang=js&"
import style0 from "./TechDetail.vue?vue&type=style&index=0&id=48560e17&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48560e17",
  null
  
)

export default component.exports