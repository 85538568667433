<template>
  <div class="admin">
    <h2>Admin</h2>

    <footer>
      <button class="button" @click="logOut">Log Out</button>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'admin',
  methods: {
    logOut() {
      this.$store.commit('SET_AUTH_TOKEN', null);
      this.$router.replace('/');
    },
  },
  beforeCreate() {
    if (!this.$store.state.authToken) {
      this.$router.replace('/');
    }
  },
};
</script>
