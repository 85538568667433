<template>
  <div class="intro" :class="{ entered }">
    <IntroEntry v-if="!entered" @hide="entered = true" />
    <IntroHeader ref="header" />
    <BackgroundDots />

    <div class="intro-content">
      <!-- SECTION: INTRO TEXT -->
      <div class="l-screen l-a-left content-container">
        <h1 class="t-headline">
          what is the <br>
          future of <br>
          technology and <br>
          innovation for <br>
          real estate?
        </h1>
      </div>
      <!-- SECTION: INTRO TEXT -->
      <div class="l-screen l-a-left content-container">
        <h1 class="t-headline">
          where can we<br>
          look for <br>
          meaningful<br>
          insights to help<br>
          us solve <br>
          real estate's<br>
          biggest problems?
        </h1>
      </div>
      <!-- SECTION: LOGO ANIMATION -->
      <Parallax :from="{ y: 0 }" :to="{ y: 100 }">
        <div class="l-screen l-a-center content-container-narrow logo-block" slot-scope="{ parallax }">
          <AnimatedLogo :collapsed="parallax.y < 50" :stacked="parallax.y < 47" />
        </div>
      </Parallax>
      <!-- SECTION: INTRO TEXT -->
      <div class="l-screen l-a-left content-container">
        <div class="">
          <h1 class="t-headline">
          The MIT Tech Tracker is a tool <br>
          to scout, track and predict <br>
          the future of real estate <br>
          technology.<br>
          </h1>
          <div class="action-buttons" style="max-width: 500px">
            <IntroButton direction="down" v-scroll-to="{ el: '#developed-by', offset: -50 }">
              Learn More About the MIT Tech Tracker
            </IntroButton>
            <IntroButton direction="right" @click="toRanking">
              Start Tracking Technologies Now!
            </IntroButton>
          </div>
        </div>
      </div>
      <div class="l-screen l-a-left content-container">
        <h1 id="developed-by" class="t-headline">
          Developed by the <br>
          MIT Real Estate Innovation Lab, <br>
          this tool is an outcome from an  <br>
          advanced, interactive, and  <br>
          extensive educational  <br>
          experience with JLL. <br>
        </h1>
      </div>

      <!-- WHY Section -->

      <div id="why">
        <div class="section-title content-container">
          <h2 class="">why we do it?</h2>
        </div>
        <!-- SECTION: INTRO TEXT -->
        <div class="l-screen l-a-left content-container">
          <h1 class="t-headline">
            to scout and track <br>
            technologies with <br>
            data, not gut...
          </h1>
        </div>
        <div class="l-screen l-a-left content-container">
          <h1 class="t-headline">
            in a way that is relevant <br>
            for the real estate <br>
            industry
          </h1>
        </div>

        <!-- SECTION: COVERS SCROLL -->
        <div class="no-overflow">
          <Parallax :from="{ x: docWidth/2 }" :to="{ x: -1 * docWidth/4 }">
            <CoverItems slot-scope="{ parallax }" :style="`transform: translateX(${parallax.x}px)`" />
          </Parallax>
        </div>

        <div class="l-screen l-a-left content-container">
          <h1 class="t-headline">
            beyond all the lists...
          </h1>
        </div>

        <!-- SECTION: HYPE ANIMATION -->
        <Parallax :from="{ y: 0 }" :to="{ y: 100 }" :use-el-height="false">
          <div class="hype-section no-overflow" slot-scope="{ parallax }">
            <h1 class="t-headline content-container">
              <div :class="{ active: (parallax.y > 50) }">
                <div class="hype-text">without</div>
                </div>
              <div :class="{ active: (parallax.y > 65) }">
                <div class="hype-text">all</div>
              </div>
              <div :class="{ active: (parallax.y > 75) }">
                <div class="hype-text">the</div>
              </div>
              <div :class="{ active: (parallax.y > 80) }">
                <div class="hype-text">hype</div>
              </div>
            </h1>
            <div class="hype-interactive" :class="{ active: parallax.y > 85 }">
              <div class="hype-bars">
                <div class="hype-bar" v-for="i in hypeBars" :key="`hype${i}`" :style="`height: ${i}0px`"></div>
              </div>
            </div>
          </div>
        </Parallax>

        <!-- SECTION: HEART PARALLAX -->
        <div class="gradient-background no-overflow">
          <Parallax :from="{ y: 0 }" :to="{ y: -100 }">
          <div class="l-screen l-a-right heart-section page-container" slot-scope="{ parallax }">
            <div class="backgrounds">
              <img class="starting-background" src="../assets/hearts-start.svg" alt="" :style="`transform: translateY(${parallax.y}px)`">
              <img class="ending-background" src="../assets/hearts-end.svg" alt="" :style="`transform: translateY(${parallax.y}px)`">
            </div>
            <div class="content-background">
              <img src="../assets/hearts-content.svg" alt="to see past likes" :style="`transform: translateY(${parallax.y * 6}px)`">
            </div>
            <div class="overlays">
              <img class="starting-overlay-background" src="../assets/hearts-start-overlay.svg" alt="" :style="`transform: translateY(${parallax.y * 4}px)`">
              <img class="ending-overlay-background" src="../assets/hearts-end-overlay.svg" alt="" :style="`transform: translateY(${parallax.y * 4}px)`">
            </div>
          </div>
          </Parallax>

          <!-- SECTION: ARROW PARALLAX -->
          <Parallax :from="{ y: 0 }" :to="{ y: -100 }">
            <div class="l-screen l-a-right arrow-section page-container" slot-scope="{ parallax }">
              <div class="arrow-background" ><img src="../assets/arrow-light.svg" alt=""></div>
              <div class="arrow-midground"><img src="../assets/arrow-mid.svg" alt="" :style="`transform: translateY(${parallax.y * 2}px)`"></div>
              <div class="arrow-foreground" ><img src="../assets/arrow-dark.svg" alt="to sort signals from noise" :style="`transform: translateY(${parallax.y * 4}px)`"></div>
            </div>
          </Parallax>

          <!-- SECTION: NUMBER PARALLAX -->
          <div class="bg-white numbers-section l-screen">
            <Parallax :from="{ x: 0 }" :to="{ x: 500 }">
              <div class="numbers-section--pre" slot-scope="{ parallax }">
                <div class="numbers-row numbers-light" :style="`transform: translateX(-${parallax.x}px)`" />
                <div class="numbers-row numbers-light" :style="`transform: translateX(${parallax.x}px)`" />
                <div class="numbers-row numbers-med" :style="`transform: translateX(-${parallax.x}px)`" />
                <div class="numbers-row numbers-med" :style="`transform: translateX(${parallax.x}px)`" />
              </div>
            </Parallax>
            <Parallax :from="{ x: 0 }" :to="{ x: 500 }">
              <div class="numbers-section--content" slot-scope="{ parallax }">
                <div class="numbers-row numbers-dark" :style="`transform: translateX(-${parallax.x}px)`" />
                <div class="numbers-row numbers-dark" :style="`transform: translateX(${parallax.x}px)`" />
                <div class="numbers-row numbers-dark" :style="`transform: translateX(-${parallax.x}px)`" />
                <div class="numbers-row numbers-dark" :style="`transform: translateX(${parallax.x}px)`" />
                <div class="numbers-row numbers-dark" :style="`transform: translateX(-${parallax.x}px)`" />
                <div v-if="parallax.x > 150" class="text-block text-block-1 t-subheadline">to progress </div>
                <div v-if="parallax.x > 175" class="text-block text-block-2 t-subheadline"> with data, </div>
                <div v-if="parallax.x > 200" class="text-block text-block-3 t-subheadline"> not gut, </div>
              </div>
            </Parallax>
            <div class="bg-white">
              <Parallax :from="{ x: 0 }" :to="{ x: 500 }">
                <div class="numbers-section--post" slot-scope="{ parallax }">
                  <div class="numbers-row numbers-med" :style="`transform: translateX(${parallax.x}px)`" />
                  <div class="numbers-row numbers-med" :style="`transform: translateX(-${parallax.x}px)`" />

                  <div class="numbers-row numbers-light" :style="`transform: translateX(${parallax.x}px)`" />
                  <div class="numbers-row numbers-light" :style="`transform: translateX(-${parallax.x}px)`" />
                </div>
              </Parallax>
            </div>
          </div>
        </div>
        <div class="bg-white">
          <div class="content-container">
            <svg width="100%" height="100%" viewBox="0 0 925 288" style="transform: translate(-15vw, 10vh); fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;">
              <g transform="matrix(1,0,0,1,-97,-167)">
                <g transform="matrix(1,0,0,1,102.377,172.761)">
                  <g opacity="0.55">
                    <clipPath id="_clip1">
                      <rect x="-9" y="-7" width="317.258" height="284.31" clip-rule="nonzero"/>
                    </clipPath>
                    <g clip-path="url(#_clip1)">
                      <g transform="matrix(1,0,0,1,298.708,181.465)">
                        <path d="M0,-102.036C-0.134,-70.488 -11.225,-48.152 -26.235,-27.448C-46.487,0.487 -71.525,23.963 -96.593,47.359C-112.746,62.434 -129.191,77.194 -145.441,92.165C-147.573,94.13 -148.893,95.482 -151.848,92.773C-184.127,63.194 -217.072,34.304 -246.935,2.202C-264.673,-16.866 -281.519,-36.83 -290.792,-61.729C-302.886,-94.202 -301.05,-125.473 -278.067,-152.605C-246.259,-190.154 -187.739,-190.216 -153.059,-153.594C-149.898,-150.256 -148.573,-149.576 -144.978,-153.419C-103.734,-197.518 -32.166,-186.836 -7.307,-133.038C-2.019,-121.593 -0.157,-109.397 0,-102.036Z" style="fill:rgb(246,246,246);fill-rule:nonzero;stroke:rgb(18,18,36);stroke-width:1.1px;"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g transform="matrix(1,0,0,1,102.377,172.761)">
                  <g opacity="0.48">
                  <g transform="matrix(1,0,0,1,833.307,185.626)">
                      <path d="M0,-67.509L79.201,11.66L83.979,6.884L4.779,-72.286L67.739,-72.362L67.641,-79.169L-6.662,-78.945L-6.886,-4.672L-0.076,-4.573L0,-67.509Z" style="fill:white;fill-rule:nonzero;stroke:rgb(0,1,1);stroke-width:1.01px;stroke-miterlimit:4;"/>
                  </g>
                  </g>
                </g>
              </g>
            </svg>
            <ScrollAware @in-view="$refs.actionLogoBlock1.expand()">
              <ActionLogoBlock ref="actionLogoBlock1">
                <div class="action-buttons">
                  <IntroButton direction="down" v-scroll-to="{ el: '#how', offset: -50 }">
                    Learn More About the MIT Tech Tracker
                  </IntroButton>
                  <IntroButton direction="right" @click="toRanking">
                    Start Tracking Technologies Now
                  </IntroButton>
                </div>
              </ActionLogoBlock>
            </ScrollAware>
          </div>
        </div>
      </div>
      <div class="bg-white" style="margin-bottom: -10%">
        <div id="how">
          <div class="section-title content-container">
            <h2 class="">how we do it?</h2>
          </div>

          <div class="content-container">
            <div class="research__section section--1">
              <div class="background-pattern"><img class="animate-pulse" src="../assets/pattern_how1.svg" alt=""></div>
              <h3 class="t-headline">
                <span>1</span>
                <span>
                  We find where <br> technologies come <br> from ...
                </span>
              </h3>
            </div>

            <div class="research__section section--2">
              <div class="background-pattern" style="left: 70%;top: 80%;"><img class="animate-pulse" src="../assets/pattern_how2.svg" alt=""></div>
              <h3 class="t-headline">
                <span>2</span>
                <span>...and how <br> technologies move from <br> the lab...</span>
              </h3>
            </div>

            <div class="research__section section--3">
              <div class="background-pattern" style="top:80%"><img class="animate-pulse" src="../assets/pattern_how3.svg" alt=""></div>
              <h3 class="t-headline">
                <span>3</span>
                <span>..to track when technologies <br> become available in the <br> market...</span>
              </h3>
            </div>

            <div class="research__section section--4">
              <div class="background-pattern" style="top: 90%;left: 30%;"><img class="animate-pulse" src="../assets/pattern_how4.svg" alt=""></div>
              <h3 class="t-headline">
                <span>4</span>
                <span>...and ultimately how <br> technologies <br> become a part <br> of our life.</span>
              </h3>
            </div>
          </div>

          <div class="content-container l-screen l-a-left">
            <h2 class="t-headline">With a growing dataset of...</h2>
            <div class="tech-odometer">
              <ScrollAware @in-view="numTechs = 210">
                <div class="t-subheadline">
                  <VOdometer class="tech-odometer-number" :value="numTechs" />
                  Technologies
                </div>
                <svg width="100%" height="100%" viewBox="0 0 1289 443" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;">
                  <g transform="matrix(1,0,0,1,2,2)">
                      <g transform="matrix(1,0,0,1,-475,-25)">
                          <g transform="matrix(1,0,0,1,1540.11,25.5)">
                              <path d="M0,438.24C121.017,438.24 219.12,340.137 219.12,219.12C219.12,98.103 121.017,0 0,0C-121.017,0 -219.12,98.103 -219.12,219.12C-219.12,340.137 -121.017,438.24 0,438.24" style="fill:white;fill-rule:nonzero;"/>
                          </g>
                          <g transform="matrix(1,0,0,1,1540.11,25.5)">
                              <path d="M0,438.24C121.017,438.24 219.12,340.137 219.12,219.12C219.12,98.103 121.017,0 0,0C-121.017,0 -219.12,98.103 -219.12,219.12C-219.12,340.137 -121.017,438.24 0,438.24Z" style="fill:none;fill-rule:nonzero;stroke:rgb(35,31,32);stroke-width:1px;"/>
                          </g>
                          <g transform="matrix(1,0,0,1,475,25)">
                              <g opacity="0.9">
                                  <g>
                                      <clipPath id="_clip1">
                                          <rect x="584.4" y="147.598" width="63.092" height="49.642" clip-rule="nonzero"/>
                                      </clipPath>
                                      <g clip-path="url(#_clip1)">
                                          <g transform="matrix(1,0,0,1,637.942,181.799)">
                                              <path d="M0,-15.031C-0.02,-10.384 -1.656,-7.093 -3.871,-4.043C-6.859,0.072 -10.553,3.53 -14.252,6.977C-16.635,9.197 -19.062,11.372 -21.459,13.577C-21.774,13.866 -21.968,14.066 -22.405,13.667C-27.167,9.309 -32.028,5.053 -36.434,0.324C-39.051,-2.484 -41.537,-5.425 -42.905,-9.093C-44.69,-13.877 -44.419,-18.484 -41.028,-22.48C-36.334,-28.012 -27.7,-28.021 -22.583,-22.626C-22.117,-22.134 -21.921,-22.034 -21.391,-22.6C-15.306,-29.097 -4.746,-27.523 -1.078,-19.598C-0.298,-17.912 -0.023,-16.115 0,-15.031" style="fill:rgb(246,246,246);fill-rule:nonzero;"/>
                                          </g>
                                          <g transform="matrix(1,0,0,1,637.942,181.799)">
                                              <path d="M0,-15.031C-0.02,-10.384 -1.656,-7.093 -3.871,-4.043C-6.859,0.072 -10.553,3.53 -14.252,6.977C-16.635,9.197 -19.062,11.372 -21.459,13.577C-21.774,13.866 -21.968,14.066 -22.405,13.667C-27.167,9.309 -32.028,5.053 -36.434,0.324C-39.051,-2.484 -41.537,-5.425 -42.905,-9.093C-44.69,-13.877 -44.419,-18.484 -41.028,-22.48C-36.334,-28.012 -27.7,-28.021 -22.583,-22.626C-22.117,-22.134 -21.921,-22.034 -21.391,-22.6C-15.306,-29.097 -4.746,-27.523 -1.078,-19.598C-0.298,-17.912 -0.023,-16.115 0,-15.031Z" style="fill:none;fill-rule:nonzero;stroke:rgb(18,18,36);stroke-width:1.1px;stroke-miterlimit:10;"/>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </g>
                          <g transform="matrix(1,0,0,1,475,25)">
                              <g opacity="0.48">
                                  <g transform="matrix(1,0,0,1,69.0574,145.845)">
                                      <path d="M0,83.27L-57.695,-12.708L-63.488,-9.229L-5.792,86.749L-66.901,71.591L-68.452,78.22L3.698,95.978L21.883,23.965L15.299,22.222L0,83.27Z" style="fill:white;fill-rule:nonzero;stroke:rgb(0,1,1);stroke-width:1.01px;"/>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
              </ScrollAware>
            </div>
          </div>

          <div class="content-container" style="margin-top: 20vh; margin-bottom: 20vh;">
            <ScrollAware @in-view="handleTechTimingBlock">
              <TechnologyTimingBlock :active="technologyTimingActive" />
            </ScrollAware>
          </div>

          <div class="content-container">
            <h2 class="t-headline">
              Helping science and design to create <br>
              strategies and products for the<br>
              real estate industry...
            </h2>
            <h2 class="t-headline" style="text-align: right; margin-top: 25vh">
              and unlock technology<br>
              to make human life better...
            </h2>
          </div>

          <div class="content-container">
            <ScrollAware @in-view="$refs.actionLogoBlock2.expand()">
              <ActionLogoBlock ref="actionLogoBlock2">
                <div class="action-buttons">
                  <IntroButton direction="down" v-scroll-to="{ el: '#what', offset: -50 }">
                    Learn More About the MIT Tech Tracker
                  </IntroButton>
                  <IntroButton direction="right" @click="toRanking">
                    Start Tracking Technologies Now
                  </IntroButton>
                </div>
              </ActionLogoBlock>
            </ScrollAware>
          </div>

        </div>

        <div id="what">
          <div class="section-title content-container">
            <h2 class="">what you get?</h2>
          </div>

          <!-- SECTION: FEATURES -->
          <div class="l-padded content-container">
            <h2 class="t-headline">a way to rank technologies...</h2>
            <img src="../assets/mit-top-techs.svg" alt="" class="block-image">
          </div>

          <div class="l-padded content-container">
            <h2 class="t-headline">technology profiles ...</h2>
            <img src="../assets/tech-profiles.svg" alt="" class="block-image">
          </div>

          <div class="l-padded content-container" style="">
            <h2 class="t-headline">core activity and milestone data...</h2>
            <img src="../assets/tech-activity.svg" alt="" class="block-image" style="margin-top: -10%;">
          </div>
          <div class="content-container categories-index">
            <h2 class="t-headline">linked to strategies...</h2>
            <div class="content-container-narrow">
              <div class="t-header-label">category</div>
              <a href="#" v-scroll-to="{ el: '#automation', offset: -250 }" class="t-headline t-bold">Automation</a>
              <a href="#" v-scroll-to="{ el: '#sustainability', offset: -250 }" class="t-headline t-bold">Sustainability</a>
              <a href="#" v-scroll-to="{ el: '#hybrid-work', offset: -250 }" class="t-headline t-bold">Hybrid Work</a>
              <a href="#" v-scroll-to="{ el: '#analytics', offset: -250 }" class="t-headline t-bold">Data Analytics</a>
              <a href="#" v-scroll-to="{ el: '#health-wellbeing', offset: -250 }" class="t-headline t-bold">Health & Wellbeing</a>
            </div>
          </div>

          <div class="l-screen">

            <h2 id="automation" class="content-container t-headline">technologies that change...</h2>
            <div class="bg-white">
              <div class="content-container-narrow category-intro">
                <div class="category-description">
                  <div class="t-header-label">category</div>
                  <div class="t-headline t-bold">Automation</div>
                  <p class="t-paragraph-standalone">The science is clear. Automation of job tasks is linked to both the creation and elimination of job tasks. To see where automation is impacting the built environment, we categorize technologies that can have an impact.</p>
                </div>
              </div>
            </div>

            <div class="category-image-wrapper">
              <div class="category-image"><img src="../assets/category/automation.jpeg" alt=""></div>
              <div class="content-container">
                <div class="category-image-overlay">
                  <div class="t-header-label">Technology</div>
                  <div class="t-headline t-bold">Hypercells</div>
                  <p>Hypercells are shape-shifting robotics cubes that start out as a 10-centimetre cubes but can morph their elastic skin into a sphere using six internal pistons. By shifting an internal weight, the ball can roll in any direction. Each cell contains a tiny computer chip to sense its environment, avoid obstacles and communicate with each other.The cells use magnets to join up.</p>
                </div>
              </div>
            </div>

            <!-- SECTION: CATEGORY -->
            <h2 id="sustainability" class="content-container  t-headline">technologies that change...</h2>
            <div class="bg-white">
              <div class="content-container-narrow category-intro">
                <div class="category-description">
                  <div class="t-header-label">category</div>
                  <div class="t-headline t-bold">Sustainability</div>
                  <p class="t-paragraph-standalone">Buildings are responsible for one third of global carbon emissions. Technologies that can help minimize waste, decrease resource use and optimize building reuse help society combat climate change. </p>
                </div>
              </div>
            </div>

            <div class="category-image-wrapper">
              <div class="category-image"><img src="../assets/category/sustainability.jpg" alt=""></div>
              <div class="content-container">
                <div class="category-image-overlay">
                  <div class="t-header-label">Technology</div>
                  <div class="t-headline t-bold">Photovoltaic Facade</div>
                  <p>Photovoltaic glass (PV glass) is a technology that enables the conversion of light into electricity. To do so, the glass incorporates transparent semiconductor-based photovoltaic cells, which are also known as solar cells that are sandwiched between two sheets of glass.</p>
                </div>
              </div>
            </div>

            <!-- SECTION: CATEGORY -->
            <h2 id="hybrid-work" class="content-container  t-headline">technologies that change...</h2>
            <div class="bg-white">
              <div class="content-container-narrow category-intro">
                <div class="category-description">
                  <div class="t-header-label">category</div>
                  <div class="t-headline t-bold">Hybrid Work</div>
                  <p class="t-paragraph-standalone">Pandemics, extreme weather events, productivity, diversity and inclusion are all changing where and how we work. These technologies help to support the spectrum of the human experience for getting the job done. </p>
                </div>
              </div>
            </div>

            <div class="category-image-wrapper">
              <div class="category-image"><img src="../assets/category/hybrid_work.jpeg" alt=""></div>
              <div class="content-container">
                <div class="category-image-overlay">
                  <div class="t-header-label">Technology</div>
                  <div class="t-headline t-bold">Immersive Workspace</div>
                  <p>Driven by smart technology and the Internet of Things, immersive technologies such as augmented and virtual reality have provided training and preparation for immersive conference room services for mainstream companies. Employees will eventually be able to use natural gestures to drive complex processes.</p>
                </div>
              </div>
            </div>

            <!-- SECTION: CATEGORY -->
            <h2 id="analytics" class="content-container  t-headline">technologies that change...</h2>
            <div class="bg-white">
              <div class="content-container-narrow category-intro">
                <div class="category-description">
                  <div class="t-header-label">category</div>
                  <div class="t-headline t-bold">Data Analytics</div>
                  <p class="t-paragraph-standalone">Recoognition of our human experience through data collection of events, documents and images is a stepping stone in our digital economy. These technologies help us recognize, sort and make intelliegence ethically from our everday life in cities.</p>
                </div>
              </div>
            </div>

            <div class="category-image-wrapper">
              <div class="category-image"><img src="../assets/category/analytics.jpeg" alt=""></div>
              <div class="content-container">
                <div class="category-image-overlay">
                  <div class="t-header-label">Technology</div>
                  <div class="t-headline t-bold">Dark Analytics</div>
                  <p>Dark analytics refers to the analysis using dark data. Dark data is data which is acquired through various computer network operations but not used in any manner to derive insights or for decision making. IBM estimates that roughly 90 percent of data generated by sensors and analogue-to-digital conversions never get used.</p>
                </div>
              </div>
            </div>

            <!-- SECTION: CATEGORY -->
            <h2 id="health-wellbeing" class="content-container  t-headline">technologies that change...</h2>
            <div class="bg-white">
              <div class="content-container-narrow category-intro">
                <div class="category-description">
                  <div class="t-header-label">category</div>
                  <div class="t-headline t-bold">Health and Wellbeing</div>
                  <p class="t-paragraph-standalone">Collective human health is at the heart of growth in our society. Buildings and cities are progressively shifting towards more sanitation, mindfulness and care with each passing decade. These are the technologies that will help us push human health to the next level for the built environment.</p>
                </div>
              </div>
            </div>

            <div class="category-image-wrapper">
              <div class="category-image"><img src="../assets/category/health_wellbeing.jpg" alt=""></div>
              <div class="content-container">
                <div class="category-image-overlay">
                  <div class="t-header-label">Technology</div>
                  <div class="t-headline t-bold">Self-Cleaning Materials</div>
                  <p>Self-cleaning surfaces are a class of materials with the inherent ability to remove any debris or bacteria from their surfaces in a variety of ways. The self-cleaning functionality of these surfaces is commonly inspired by natural phenomena observed in lotus leaves, gecko feet, and water striders to name a few.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="content-container footer-logo">
          <ScrollAware @in-view="$refs.actionLogoBlock3.expand()">
            <div class="footer-logo-wrapper">
              <ActionLogoBlock ref="actionLogoBlock3">
                <div style="text-align: center">
                  <IntroButton direction="right" @click="toRanking">
                    Start Tracking Technologies Now
                  </IntroButton>
                </div>
              </ActionLogoBlock>
            </div>
          </ScrollAware>
        </div>

      </div>
      <div class="bg-white footer">
        <img src="../assets/footer.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import VOdometer from 'vue-odometer';
import { Parallax } from 'leaps';
import AnimatedLogo from '../components/icons/AnimatedLogo.vue';
import BackgroundDots from './intro_partials/BackgroundDots.vue';
import IntroButton from './intro_partials/IntroButton.vue';
import IntroEntry from './intro_partials/IntroEntry.vue';
import IntroHeader from './intro_partials/IntroHeader.vue';
import CoverItems from './intro_partials/CoverItems.vue';
import ActionLogoBlock from './intro_partials/ActionLogoBlock.vue';
import TechnologyTimingBlock from './intro_partials/TechnologyTimingBlock.vue';
import ScrollAware from '../components/ScrollAware.vue';

import 'odometer/themes/odometer-theme-default.css';

export default {
  name: 'Intro',
  components: {
    ActionLogoBlock,
    AnimatedLogo,
    BackgroundDots,
    CoverItems,
    IntroButton,
    IntroEntry,
    IntroHeader,
    Parallax,
    ScrollAware,
    TechnologyTimingBlock,
    VOdometer,
  },
  data() {
    return {
      entered: false,
      hypeBars: [1, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 11, 13, 16, 17, 18, 23, 24, 33, 42, 47, 58, 77, 100],
      logoBlockCollapsed: true,
      logoBlockStacked: true,
      numTechs: 100,
      technologyTimingActive: false,
    };
  },
  computed: {
    docHeight() {
      return window.document.documentElement.clientHeight;
    },
    docWidth() {
      return window.document.documentElement.clientWidth;
    },
  },
  methods: {
    toRanking() {
      this.$router.push({ name: 'ranking' });
    },
    handleAnimatedLogoInView(offset) {
      if (offset.y < 150) {
        setTimeout(() => {
          this.logoBlockStacked = false;
        }, 500);
        setTimeout(() => {
          this.logoBlockCollapsed = false;
        }, 1000);
      }
    },
    handleTechTimingBlock(offset) {
      if (offset.y < document.documentElement.clientHeight * 0.7) {
        this.technologyTimingActive = true;
      }
    },
  },
};
</script>
<style lang="postcss">
/*
  Baseline Layout
 */
.intro {
  --color-gold: #faab1a;
  --color-red: #009761;
  --color-green: #d52c39;

  --title-offset: 150px;
  --font-size-header: 48px;
  --font-size-subheader: 36px;
  --font-size-lead: 18px;
  --font-size-body: 16px;
  --font-size-small: 14px;

  --hype-text-height: 60px;

  font-family: var(--font-mono);
  /* height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden; */

  /*
    Containers
  */
  .page-container {
    position: relative;
  }
  .content-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .content-container-narrow {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  /*
    Sizing utilities
  */
  .l-screen {
    min-height: 100vh;
  }
  .l-padded {
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
  .l-a-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .l-a-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  .l-a-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  /*
    Type
  */
  .t-headline {
    font-size: var(--font-size-header);
    line-height: 1.4;
    z-index: 1;
  }
  .t-subheadline {
    font-size: var(--font-size-subheader);
    line-height: 48px;
  }
  .t-header-label {
    font-size: var(--font-size-lead);
    font-weight: bold;
    color: #666;
    text-transform: uppercase;
  }
  .t-bold {
    font-weight: bold;
  }
  .t-lead {
    font-size: var(--font-size-lead);
    line-height: 22px;
  }
  .t-body {
    font-size: var(--font-size-body);
    line-height: 20px;
  }
  .t-small {
    font-size: var(--font-size-small);
    line-height: 18px;
  }
  .t-paragraph-standalone {
    font-size: var(--font-size-body);
    max-width: 30em;
    margin-bottom: 80px;
  }
  /*
    Misc
  */
  .bg-white {
    background-color: white;
    padding: 1px 0;
  }
  .no-overflow {
    overflow-x: hidden;
  }
  .action-buttons {
    max-width: 600px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    button:first-child {
      /* margin-right: 160px; */
    }
  }
  .section-title {
    top: var(--title-offset);
    position: sticky;
    z-index: 10;
    padding: 20px;
    h2 {
      font-size: var(--font-size-subheader);
      display: inline-block;
      padding: 10px 20px;
      border-radius: 100px;
      background-color: black;
      color: white;
    }
  }
  .hype-interactive {
    position: absolute;
    top: 0;
    left: 200px;
    width: 100%;
    height: 100%;
  }
  .hype-bars {
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    overflow-y: hidden;
  }
  .hype-bar {
    display: inline-block;
    border-left: 2px solid var(--color-black);
    transition: all 1s ease-in-out;
  }
  .hype-interactive:not(.active) .hype-bar {
    height: 0 !important;
  }
  .hype-section.active {
    .t-headline div {
      margin-bottom: 100px;
    }
    .hype-bars .hype-bar {
      transform: translateY(0);
    }
  }

  .gradient-background {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 82%, rgba(255, 255, 255, 0) 100%);
    > * {
      z-index: 1;
    }
  }

  .heart-section {
    margin-top: 20vh;
    position: relative;

    /* Fixed aspect ratio for heart backgrounds */
    .backgrounds {
      width: 100%;
      padding-top: 120%;
    }
    .starting-overlay-background,
    .ending-overlay-background,
    .starting-background,
    .ending-background {
      position: absolute;
      left: -5vw;
      right: -5vw;
      width: 110vw;
      max-width: none;
    }
    .starting-overlay-background,
    .starting-background {
      top: 0;
    }
    .ending-overlay-background,
    .ending-background {
      bottom: 0;
    }
    .content-background {
      z-index: 1;
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
      img {
        max-width: 700px;
        width: 80%;
      }
    }
  }

  .arrow-section {
    position: relative;
    .arrow-background {
      display: block;
      width: 100%;
      transform: translateY(-8%);
    }
    .arrow-midground,
    .arrow-foreground {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
    }
    /* img {
      width: 110vw;
      max-width: 1400px;
      margin: 0 auto;
    } */
  }

  .background-pattern {
    opacity: 0.7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: none;
  }

  .research__section {
    display: flex;
    align-items: center;
    min-height: 400px;
    margin: 80px auto;
    padding: 80px 0;
    position: relative;

    &.section--1 {
      justify-content: space-between;
      .t-headline span {
        display: block;
      }
    }
    &.section--2 {
      .t-headline {
        width: 100%;
        margin-left: 20%;
      }
    }
    &.section--3 {
      .t-headline {
        width: 100%;
        margin-left: 30%;
        margin-right: 10%;
        span {
          text-align: right;
        }
        span + span:after {
          left: auto;
          right: 0;
        }
      }
    }
    &.section--4 {
      justify-content: flex-end;
      .t-headline span {
        display: block;
        text-align: right;
      }
      .t-headline span + span:after {
        left: auto;
        right: 0;
      }
    }

    .t-headline {
      span {
        position: relative;
        display: block;
        margin-bottom: 20px;
      }
      span + span:after {
        content: '';
        position: absolute;
        top: 110%;
        left: 0;
        display: block;
        height: 100px;
        border-left: 2px solid var(--tt-page-text-color);
      }
    }
  }
  .block-image {
    margin-top: 120px;
    margin-left: 80px;
  }
  .tech-odometer {
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      left: 0;
      width: 120%;
      top: 50%;
      height: 120%;
    }
  }
  .tech-odometer .t-subheadline {
    display: flex;
    align-items: baseline;
    margin: 100px 0;
  }
  .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    font-family: var(--font-mono) !important;
  }
  .tech-odometer-number {
    font-size: 120px;
    font-weight: bold;
    padding-right: 20px;
  }
  .logo-block {
    --logosize: 300px;
    >>> .icon-logo svg {
      width: 80vw;
      max-width: 800px;
      height: auto;
    }
  }
  .hype-section {
    position: relative;
    padding: 20vh 20px 0;
    min-height: calc(8 * var(--hype-text-height));
    .t-headline {
      div {
        height: var(--hype-text-height);
        margin-bottom: var(--hype-text-height);
        position: relative;
        .hype-text {
          visibility: hidden;
        }
        &.active .hype-text {
          visibility: visible;
        }
      }
    }
  }
  .numbers-section--content {
    position: relative;

    .text-block {
      position: absolute;
      background-color: white;
      height: 150px;
      padding: 40px 80px;
      z-index: 9;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 82%, rgba(255, 255, 255, 0) 100%);
    }
    .text-block-1 {
      top: 200px;
      right: 15vw;
    }
    .text-block-2 {
      top: 400px;
      right: 60vw;
    }
    .text-block-3 {
      top: 600px;
      right: 15vw;
    }
  }
  .numbers-row {
    height: 125px;
    width: 200vw;
    margin-left: -50%;
    margin-bottom: 80px;
    background-size: cover;
    background-repeat: repeat-x;
    &.numbers-light {
      background: url('~@/assets/numbers-light.svg');
    }
    &.numbers-med {
      background: url('~@/assets/numbers-med.svg');
    }
    &.numbers-dark {
      background: url('~@/assets/numbers-dark.svg');
    }
  }
  .categories-index {
    .content-container-narrow {
      padding: 100px 0;
      font-family: var(--font-sans);
    }
    a {
      display: block;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .category-image-wrapper {
    height: 200vh;
    margin-bottom: 80px;
    .category-image {
      height: calc(100vh - 150px);
      position: sticky;
      top: 160px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  .category-image-overlay {
    z-index: 1;
    min-height: 50vh;
    width: 50%;
    background: rgba(0,0,0,0.6);
    color: white;
    position: relative;
    padding: 80px 20px;
    font-family: var(--font-sans);
    .t-header-label {
      color: white;
    }
    p {
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      padding: 30px 0;
      margin: 20px 0;
      font-size: var(--font-size-body);
    }
  }
  .category-intro {
    font-family: var(--font-sans);
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .footer {
    overflow: hidden;
    img {
      max-width: 120vw;
      width: 120vw;
      margin-left: 0vw;
      transform: translateY(30%);
    }
  }

  .animate-pulse {
    animation: pulse 10s linear infinite;
  }

  .footer-logo {
    .action-logo-block {
      min-height: 100vh;
      margin-bottom: 0;
    }
    .logo-action-container {
      position: sticky;
      top: 300px;
    }
  }
}
@media screen and (max-width: 900px) {
  .intro {
    --title-offset: 100px;
    --font-size-header: 28px;
    --font-size-subheader: 20px;
    --font-size-lead: 16px;
    --font-size-body: 14px;
    --font-size-small: 12px;
    --hype-text-height: 40px;

    .l-screen {
      min-height: 60vh;
    }
    .logo-block {
      --logosize: 150px;
    }
    .research__section .t-headline {
      margin-left: 0 !important;
    }
    .tech-odometer-number {
      font-size: 60px;
    }
    .numbers-row {
      height: 50px;
      margin-bottom: 50px;
    }
    .numbers-section--content {
      .text-block {
        height: 80px;
        padding: 20px 60px;
      }
      .text-block-1 {
        top: 80px;
        right: 15vw;
      }
      .text-block-2 {
        top: 180px;
        right: 45vw;
        white-space: nowrap;
      }
      .text-block-3 {
        top: 280px;
        right: 15vw;
      }
    }
    .category-image-overlay {
      width: 100% !important;
    }
    .category-image-wrapper .category-image {
      top: 120px;
    }
    .block-image {
      margin-top: 40px;
      margin-left: 0;
    }
    .action-buttons {
      max-width: 400px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(5px, 20px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
