<template>
  <div class="app-main-wrapper">
    <app-header />
    <title-bar :info="info">
      <filters-description :applied-filters="appliedFilters" />
    </title-bar>
    <div class="app-main ranking">
      <div class="ranking__list">
        <header class="ranking-header grid">
          <span class="t-label col2">
            <span>Rank</span>
          </span>
          <span class="t-label col4">
            <span>Technology</span> <span class="badge">momentum</span>
          </span>
          <span class="t-label col2">
            <span><span class="no-mobile">Current</span> Milestone</span>
          </span>
        </header>
        <RankItem v-for="(t, i) in filteredTechnologies" :key="t.id" :tech="t" :rank="i + 1" />
      </div>
      <div class="ranking__filters">
        <tech-filters :applied-filters="appliedFilters" include-data-filters @change="updateFilters" />
      </div>
    </div>
    <app-footer />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import orderBy from 'lodash/orderBy';
import AppHeader from './partials/AppHeader.vue';
import AppFooter from './partials/AppFooter.vue';
import TitleBar from './partials/TitleBar.vue';
import RankItem from '../components/RankItem.vue';
import RankingInfo from '../components/RankingInfo.vue';
import TechFilters from '../components/TechFilters.vue';
import FiltersDescription from '../components/FiltersDescription.vue';

export default {
  name: 'Ranking',
  props: {},
  components: {
    AppHeader,
    FiltersDescription,
    AppFooter,
    TitleBar,
    RankItem,
    TechFilters,
  },
  data: () => ({
    sortedBy: null,
    sortAsc: false,
    sortedTechs: [],
    info: {
      label: 'About this ranking',
      component: RankingInfo,
    },
    appliedFilters: {
      datatype: '',
      momentum: '',
      techtype: '',
      category: '',
      stage: '',
      timeframe: 'all_time',
    },
  }),
  computed: {
    ...mapState({
      seen: (state) => state.seen,
      technologies: (state) => state.technologies,
    }),
    filteredTechnologies() {
      let techs = (this.technologies || []);
      if (this.appliedFilters.techtype) {
        techs = techs.filter((t) => {
          if (this.appliedFilters.techtype === 'product') {
            return t.is_prod;
          }
          if (this.appliedFilters.techtype === 'process') {
            return t.is_proc;
          }
          return true;
        });
      }
      if (this.appliedFilters.momentum) {
        techs = techs.filter((t) => t.momentum.toLowerCase() === this.appliedFilters.momentum);
      }
      if (this.appliedFilters.category) {
        techs = techs.filter((t) => t.categories.join('').toLowerCase().includes(this.appliedFilters.category));
      }
      if (this.appliedFilters.stage) {
        techs = techs.filter((t) => t.stage.toLowerCase() === this.appliedFilters.stage);
      }
      const rankAttr = `score${this.appliedFilters.datatype ? `_${this.appliedFilters.datatype}` : ''}_${this.appliedFilters.timeframe || 'all_time'}`;
      techs = techs.map((t) => ({
        ...t,
        computedRank: t[rankAttr],
      }));
      return orderBy(techs, 'computedRank', 'desc').slice(0, 25);
    },
  },
  methods: {
    updateFilters(filters) {
      this.$gtag.event('apply filters tech-ranking', { filters });
      this.appliedFilters = filters;
    },
  },
};
</script>
<style lang="postcss" scoped>
.ranking {
  display: flex;
  width: 100%;
  .ranking__list {
    width: 100%;
  }
  .ranking__filters {
    min-width: 240px;
    padding: 0 var(--space-2);
    >>> .filter-wrapper {
      position: sticky;
      top: calc(var(--tt-header-height) + var(--tt-table-header-height));
    }
  }
}
.ranking-header {
  /* border-top: 1px solid $light; */
  border-bottom: 2px solid var(--tt-page-text-color);
  position: sticky;
  background-color: var(--tt-page-background-color);
  height: var(--tt-table-header-height);
  top: calc(var(--tt-header-height) + var(--tt-table-header-height));
  z-index: var(--z-overlay1);

  .t-label {
    text-align: left;
    padding: var(--space-2) var(--space-1) var(--space-1);
  }
  .ranking__list >>> .rank-item > .grid {
    flex-wrap: nowrap;
    .col2 {
      width: auto;
    }
    .col4 {
      width: 100%;
      .tech-expansion-link {
        text-align: left;
      }
    }
  }
}

>>> .rank-item:hover {
  background-color: rgba(0,0,0,0.05);
}

@media screen and (max-width: 768px) {
  .ranking {
    flex-direction: column;
  }
  .ranking .ranking__filters {
    order: -1;
    padding: 0;
  }
  .ranking-header {
    margin-top: 20px;
    z-index: initial;
    flex-direction: column;
    > * {
      padding: 10px !important;
    }
  }
}
</style>
