<template>
  <div class="technology-timing-block">
    <h1 class="t-headline">
      and to know the time a technology takes...
    </h1>
    <div class="row" :class="{ active: active1 }">
      <div class="t-subheadline">
        From <strong>Lab/Inception</strong> to <strong>R&D</strong>
      </div>
      <div>
        average: <VOdometer class="tech-odometer-number" format="(dd).dd" :value="lab" /> YRS
      </div>
    </div>
    <div class="row" :class="{ active: active2 }">
      <div class="t-subheadline">
        From <strong>R&D</strong> to <strong>Market</strong>
      </div>
      <div>
        average: <VOdometer class="tech-odometer-number" format="(dd).dd" :value="market" /> YRS
      </div>
    </div>
    <div class="row" :class="{ active: active3 }">
      <div class="t-subheadline">
        From <strong>Market</strong> to <strong>Standards</strong>
      </div>
      <div>
        average: <VOdometer class="tech-odometer-number" format="(dd).dd" :value="life" /> YRS
      </div>
    </div>
  </div>
</template>
<script>
import VOdometer from 'vue-odometer';

export default {
  name: 'TechnologyTimingBlock',
  components: {
    VOdometer,
  },
  props: {
    active: Boolean,
  },
  data() {
    return {
      lab: 0,
      market: 0,
      life: 0,
      active1: false,
      active2: false,
      active3: false,
    };
  },
  watch: {
    active() {
      this.lab = 11.0;
      this.market = 7.3;
      this.life = 21.3;

      this.active1 = true;
      setTimeout(() => {
        this.active2 = true;
      }, 1000);
      setTimeout(() => {
        this.active3 = true;
      }, 2000);
    },
  },
};
</script>
<style lang="postcss" scoped>
.technology-timing-block {

}
.tech-odometer-number {
  font-size: var(--font-size-header) !important;
  font-weight: bold;
  min-width: 150px;
}
.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    transition: all 5s ease-in-out;
    padding: 40px;
    width: 60%;
    white-space: nowrap;
  }
  > *:last-child {
    text-align: right;
    width: 40%;
    font-size: 24px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 1px solid transparent;
    transition: all 1s ease-in-out;
  }
}
.technology-timing-block .active {
  &:after {
    border-bottom-color: black;
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
  .row > * {
    width: 100%;
    padding: 20px;
    line-height: 1;
  }
}
</style>
