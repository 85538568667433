const API_ENDPOINT = '/technologies.json';

const API_BASE = API_ENDPOINT;

const fetchChartDataForTechnology = async function fetchChartDataForTechnology(id) {
  const data = await fetch(`${API_ENDPOINT}/technologies/${id}`).then((r) => r.json());
  return data;
};

export default {
  fetchChartDataForTechnology,
  API_ENDPOINT,
  API_BASE,
};
