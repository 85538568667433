<template>
  <header class="intro-header">
    <IntroNavigation :open.sync="menuOpen" />
    <div class="intro__logo container">
      <AnimatedLogo :collapsed="collapsed" :stacked="stacked" />
    </div>
  </header>
</template>
<script>
import throttle from 'lodash/throttle';
import IntroNavigation from './IntroNavigation.vue';
import AnimatedLogo from '../../components/icons/AnimatedLogo.vue';

export default {
  name: 'IntroHeader',
  components: {
    AnimatedLogo,
    IntroNavigation,
  },
  data() {
    return {
      collapsed: false,
      stacked: false,
      menuOpen: true,
    };
  },
  methods: {
    collapseLogo() {
      setTimeout(() => {
        this.collapsed = true;
      }, 500);
      setTimeout(() => {
        this.stacked = true;
      }, 1000);
    },
    expandLogo() {
      setTimeout(() => {
        this.stacked = false;
      }, 500);
      setTimeout(() => {
        this.collapsed = false;
      }, 1000);
    },
    openMenu() {
      this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
  mounted() {
    window.addEventListener('scroll', throttle(() => {
      if (document.documentElement.getBoundingClientRect().y < -100) {
        this.collapseLogo();
        this.menuOpen = false;
      } else {
        this.expandLogo();
        this.menuOpen = true;
      }
    }, 500));
  },
};
</script>
<style lang="postcss" scoped>
.intro-header {
  background-color: white;
  width: 100%;
  padding: 20px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
}
.intro__logo {
  --logosize: 120px;
}
@media screen and (max-width: 950px) {
  .intro__logo {
    --logosize: 80px;
  }
}
</style>
