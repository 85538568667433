<template>
  <div class="app-main-wrapper">
    <app-header />
    <title-bar :info="info">
      <filters-description :applied-filters="appliedFilters" />
    </title-bar>
    <section class="app-main search">
      <form class="search-form" @submit.prevent="updateRoute">
        <label for="search" class="search-label">
          <span v-show="!internalQuery" class="search-label__text">Search for any technology...</span>
          <input id="search" class="search-input" v-model="internalQuery" type="text" />
          <button type="button" v-show="internalQuery" @click="clearSearch" class="search-clear">
            <x-icon />
          </button>
        </label>
      </form>
      <div class="technologies">
        <div class="results">
          <p class="no-results" v-if="internalQuery && filteredTechnologies.length === 0">
            No results found for "{{ internalQuery }}". <a href="#" @click.prevent="clearSearch">Clear Search</a>
          </p>
          <TechItem v-for="t in filteredTechnologies" :key="t.id" :tech="t" />
        </div>
        <div class="ranking__filters">
          <tech-filters :applied-filters="appliedFilters" @change="updateFilters" />
        </div>
      </div>
    </section>
    <app-footer />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { XIcon } from 'vue-feather-icons';
import AppFooter from './partials/AppFooter.vue';
import AppHeader from './partials/AppHeader.vue';
import TechItem from '../components/TechItem.vue';
import TechFilters from '../components/TechFilters.vue';
import TitleBar from './partials/TitleBar.vue';
import DirectoryInfo from '../components/DirectoryInfo.vue';
import FiltersDescription from '../components/FiltersDescription.vue';

export default {
  name: 'search',
  components: {
    AppHeader,
    AppFooter,
    TitleBar,
    TechFilters,
    TechItem,
    XIcon,
    FiltersDescription,
  },
  data: () => ({
    appliedFilters: {
      momentum: '',
      techtype: '',
      category: '',
      stage: '',
    },
    internalQuery: '',
    info: {
      label: 'About these technologies',
      component: DirectoryInfo,
    },
    typeFilter: '',
    typeOptions: [
      {
        name: 'All Types',
        value: '',
      },
      {
        name: 'Product',
        value: 'product',
      },
      {
        name: 'Process',
        value: 'process',
      },
    ],
  }),
  computed: {
    ...mapState({
      technologies: 'technologies',
    }),
    query() {
      return this.$route.query.q || '';
    },

    filteredTechnologies() {
      let techs = (this.technologies || []);
      if (this.query) {
        techs = techs.filter((f) => {
          const text = JSON.stringify(f).toLowerCase();
          let matchesFilter = true;
          if (this.typeFilter) {
            matchesFilter = this.typeFilter === f.type;
          }
          return text.includes(this.query.toLowerCase()) && matchesFilter;
        });
      }
      if (this.appliedFilters.techtype) {
        techs = techs.filter((t) => {
          if (this.appliedFilters.techtype === 'product') {
            return t.is_prod;
          }
          if (this.appliedFilters.techtype === 'process') {
            return t.is_proc;
          }
          return true;
        });
      }
      if (this.appliedFilters.momentum) {
        techs = techs.filter((t) => t.momentum.toLowerCase() === this.appliedFilters.momentum);
      }
      if (this.appliedFilters.category) {
        techs = techs.filter((t) => t.categories.join('').toLowerCase().includes(this.appliedFilters.category));
      }
      if (this.appliedFilters.stage) {
        techs = techs.filter((t) => t.stage.toLowerCase() === this.appliedFilters.stage);
      }
      return techs;
    },
  },
  methods: {
    clearSearch() {
      this.internalQuery = '';
    },
    // eslint-disable-next-line func-names
    updateRoute: debounce(function () {
      this.$router.push({ query: { ...this.$route.query, q: this.internalQuery } });
    }, 500),
    updateFilters(filters) {
      this.$gtag.event('apply filters all-techs', { filters });
      this.appliedFilters = filters;
    },
  },
  watch: {
    internalQuery(val) {
      this.updateRoute(val);
    },
  },
  mounted() {
    this.internalQuery = (this.$route.query || {}).q;
  },
};
</script>
<style lang="postcss" scoped>
.search {
  position: relative;
}
.search-form {
  position: sticky;
  top: calc(var(--tt-header-height) + 72px);
  z-index: var(--z-overlay1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-clear {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.search-label {
  position: relative;
  display: inline-block;
  flex: 1;
}
.search-label__text {
  font-size: var(--fs-large);
  position: absolute;
  z-index: 1;
  top: 15px;
  left: 28px;
  opacity: 0.5;
}
.search-input,
.type-select {
  border: none;
  color: 2px solid var(--tt-page-text-color);
  border-bottom: 2px solid var(--tt-page-text-color);
  padding: 0 var(--space-3);
  padding: 18px 2rem;
  display: block;
  background-color: var(--tt-page-background-color);
  font-size: var(--fs-large);
  width: 100%;
}
.results {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid var(--tt-page-text-color);
  /* margin: 0 -1rem; */
  justify-content: flex-start;
  /* padding-top: var(--space-2); */
  > * {
    flex: 1;
    min-width: 325px;
    max-width: 33.333%;
    min-height: 220px;
    padding: 1rem;
  }
}

.technologies {
  display: flex;
  .results {
    width: 100%;
  }
  .ranking__filters {
    min-width: 240px;
    padding: 0 var(--space-2);
    >>> .filter-wrapper {
      position: sticky;
      top: calc(var(--tt-header-height) + var(--tt-table-header-height) + 60px);
    }
  }
}

@media screen and (max-width: 768px) {
  .results {
    display: block;
    > * {
      max-width: 100%;
    }
  }
  .search-form {
    z-index: 1;
  }
}

</style>
