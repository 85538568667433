import { render, staticRenderFns } from "./IntroButton.vue?vue&type=template&id=2c6c6e57&scoped=true&"
import script from "./IntroButton.vue?vue&type=script&lang=js&"
export * from "./IntroButton.vue?vue&type=script&lang=js&"
import style0 from "./IntroButton.vue?vue&type=style&index=0&id=2c6c6e57&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6c6e57",
  null
  
)

export default component.exports