<template>
  <div class="app-main-wrapper">
    <app-header />
    <div class="app-main experience">
      <section class="experience">
        <nav class="experience__navigation">
          <p>The MIT Tech Tracker Experience</p>
          <ul class="pt-2">
            <li v-for="item in contentNav" :key="item.id">
              <a class="navigation-item" :class="{'is-active': item.id === activeID}" href="#" v-scroll-to="`#${item.id}`">
                {{item.title}}
              </a>
              <ul v-if="item.children">
                <li v-for="child in item.children" :key="`child-${child.id}`">
                  <a class="navigation-item" :class="{'is-active': child.id === activeID}" href="#" v-scroll-to="`#${child.id}`">
                    {{child.title}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div class="experience__content">
          <h1>The MIT Tech Tracker Guide Book</h1>
          <p class="subtitle">
            Getting from Hype to Bytes to Insights
          </p>
          <div class="content-block" id="breaking-down">
            <h2>Breaking Down the Hype</h2>
            <p>
              Where do we go to discover technologies that can help us solve problems in real estate? More often than not, we turn to the media. Movies, for example, give us a sense of what can be in the future. Iron Man (2008) gave us a vision of Volumetric Displays; Avatar (2009) gave us our first pass at operating real estate with Digital Twins; Big Hero Six (2011) helped us to envision robotic swarm construction or hypercells; and Black Panther (2019) showed us what it really means to have a smart, connected and green city. Even more practically, is the annual press review of technologies that will transform our world. As an example, annually, the MIT Technology Review, publishes “The Top 10 Breakthrough Technologies.” Although it is not geared towards real estate and the built environment, it provides an annual list of technologies to look out for today and in the near future. Yet, as exciting as these lists may be, the lists don’t track in the long-run what happened to all of those technologies living in the labs. So, we wondered, can we find technologies for real estate, without the media and the hype?
            </p>

            <div class="quote">
              <div class="quote__image rounded">
                <img src="../assets/andrea_experience.png" alt="">
                <p>
                  Dr. Andrea Chegut
                </p>
              </div>
              <p class="quote__content">“Technologies are products, processes or uses that are here to make human life better.”</p>
            </div>

            <h3 id="understanding">Understanding Technology</h3>
            <p>
              The REI Lab team embarked on the first stage of this research to sift through the market hype of new real estate trends and to learn their technology grounding in partnership with JLL. We scouted all real estate building products in the institutional owner, investor and architectural domains and tracked where they are in their development. In our first pass, we found 52 real estate building products, ranging from the newly invented “robotic moving buildings” to the recent market entry of “smart buildings” and the now every day life classic of the “big-box shopping mall”.
            </p>
            <p>
              We started with real estate building products because our stakeholders cared about what types of buildings were coming next.  For example, estimating the value proposition of green buildings that can limit climate change is a very relevant question in real estate. Historically, research has pointed to financial performance premiums for green residential and commercial buildings. To be able to assess performance with more clarity and help an industry that designs, constructs and finances buildings, we needed to know what it meant to be a green building. More poignantly, what makes a green building, a “green building” and how can I tell if one building is green over another? Or what is a “smart building” and how do I find them? These questions, although seemingly simple, were hard to answer. As the so-called words that described something new, more often than not, described something else, a building technology or group of technologies, that have been in research, development and commercialization for differing amounts of time.
            </p>
            <div class="quote">
              <div class="quote__image">
                <img src="../assets/realestate-building-products.png" alt="">
              </div>
              <p class="quote__content">
                From this analysis, the first real estate building product cycle was born.
              </p>
            </div>

            <p>
              Yet, this was not enough. Overall, we were not going far or deep enough with the real estate product lifecycle to really understand anything meaningful about the history of these technologies or to say anything beyond real estate types or categories. Because when we started to deconstruct the technologies, we discovered the technologies embedded inside of other technologies. In this way, technologies compounded over time to create more complex and meaningful tools that could help humans solve problems. Like green buildings, it is a type of building product, with various embedded technologies, that helps us as humans fight climate change.
            </p>

            <h3 id="catalogue">Creating a Catalogue of Tech</h3>
            <p>
              The insight of technology embeddedness now empowered us with our first mandate - to create a technology catalogue of real estate products, processes and uses. We put some boundaries around our search to keep the task of scouting and tracking concrete to real estate and curated a pipeline list of 206 places to find real estate technologies being developed.
            </p>

            <div class="image-two-up">
              <img src="../assets/experience/catalogue-1.png" alt="">
              <img src="../assets/experience/catalogue-2.png" alt="">
            </div>

            <div class="quote">
              <p class="quote__content">
                Starting to shift the curve. From this global scouting exercise across 206 sources, we now found products, processes, and uses for real estate technologies.
              </p>
            </div>

            <p>
              Further, we started to understand the composition of technologies. This boundary of where to find technologies also helped us to unpack the embedded technologies inside each product, process and use. When we did so, we uncovered a plethra of existing technologies that were extending their journey further and further everyday by embedding inside of other technologies.
            </p>

            <div class="quote">
              <div class="quote__image rounded">
                <img src="../assets/james_experience.jpg" alt="">
                <p>
                  James Scott
                </p>
              </div>
              <p class="quote__content">We have found at least 800+ technologies embedded in real estate technologies.</p>
            </div>

            <p>
              From this last step, the REI Lab team was now getting more comfortable, we could take the words and experiences of real estate professionals and turn them into countable technologies. Our work had taught us where technologies came from, but we did not understand yet how technologies turned from science fiction to everyday tools in the built environment. Now we needed to turn that breakdown in one-off media hype into data bytes.
            </p>
          </div>

          <div id="hype-to-bytes">

            <h2>Turning the Hype into Data Bytes</h2>
            <p>
              To move from hype to data bytes,  we engaged in an extended educational experience with our partner JLL. MIT developed six principles to lead this experience: technology scouting, a dynamic data catalogue, technology staging, awareness measuring, technology indexing and change capturing. From these principles, we designed a data science architecture to scout, collect, track and analyze technologies with the JLL team interactively across the MIT campus. With the intent, that turning our living and breathing MIT technology engine into a source of data to track technologies important for real estate was a way to protytype our research process with education
            </p>
            <div class="image-grid">
              <div class="image-grid-row row-1-3">
                <div>
                  <img src="../assets/experience/workshop-title-slide.png">
                  <img src="../assets/experience/place-technology.png">
                </div>
                <img src="../assets/experience/workshop.jpg">
              </div>
              <div class="image-grid-row row-band">
                <img src="../assets/experience/team-band.jpg" alt="">
              </div>
              <div class="image-grid-row row-1-3">
                <div>
                  <img src="../assets/experience/MITRealEstateTechnology_WorkshopRecaps-37.jpg">
                  <img src="../assets/experience/post-its.png">
                </div>
                <div>
                  <div class="image-grid-row row-1-1">
                    <img src="../assets/experience/asimov.png" alt="">
                    <img src="../assets/experience/analyzed.png" alt="">
                  </div>
                  <img src="../assets/experience/IMG_1824.png" alt="">
                </div>
              </div>
            </div>

            <h3 id="jll-mit">JLL and MIT - an Educational Experience</h3>
            <p>
              In a multi-year effort, JLL + MIT engaged in an educational experience to understand what technologies are, where they come from and how they become a part of our life. Born out of the studio culture at MIT SA+P, and combining the disciplines of economics and technology studies with real estate, we worked through various frameworks of how to look at and understand technology.
            </p>
            <p>
              Concretely this means rolling up our sleaves, working together to understand concepts and pushing the boundaries of what we knew about technologies so far. We went beyond proptech and into the forces that drive innovation through exercises in scouting for technologies, creating a technology catalogue from the MIT campus, gathering awareness data from numerous sources, learning how to identify milestones for a technology and ultimately working towards generating a technology index. JLL engaged across executive levels that led to insights in the R&D ecosystem for real estate technology and ultimately how technologies get out of the lab and into our life.
            </p>
            <p>
              Further, we dissected the meaning of the word technology in our cultural, business and academic lives. In this process, we discovered the wonderful world of invention and an opportunity for growth that solves problems and makes human life better. But most importantly, we learned how technologies evolve and how we could tap into the power of technology knowledge, in addition to what we already knew about innovation and entrepreneurship.
            </p>
            <p>
              Ultimately, REIL data shows that technologies rarely die, and this means to create a culture of R&D for the real estate industry, we need to not only understand so-called “proptech”, but also technology for real estate to align with our longer-term strategies and goals.
            </p>

            <div class="quote">
              <div class="quote__image rounded">
                <img src="../assets/andrea_experience.png" alt="">
                <p>
                  Dr. Andrea Chegut
                </p>
              </div>
              <p class="quote__content">“Technologies rarely die. Technologies evolve, pivot, embed, and stall all the time, but they pickup again and again.”</p>
            </div>

            <h3 id="mit-tech-tour">An MIT Technology Tour</h3>

            <p>
              Teams of MIT inventors across campus got to meet JLL researchers. Both inventors and researchers, learned about their invaluable connection to one another for a technology’s journey. Both groups, were deeply inspired by what each of them meant in the journey of a technology from the lab, to the market and to our lives. Importantly, they saw the wider ecosystem and the role we each played in the development and use of technology. Our team visited several labs across MIT, and we are grateful for those PIs that showcased their technologies to us.
            </p>

            <img src="../assets/experience/tech-tour.png" alt="">

            <p>
              MIT is famous for invention and innovation.  Of course, it is notorious for engineering and biotechnology. However, the school also leads in technology development for the built environment. We visited several labs across MIT to learn about technologies, they are outlined here:  Media Lab, City Science, International Design Center, Design Fabrication Group, Urban Risk Lab, Architecture, Representation and Computation Group (ARC), Self-Assembly Lab, Sustainable Design Lab, Sustainable Concrete Hub, MIT.nano and the MIT Virtual Experience Design Lab.
            </p>
            <p>
              These groups of inventors are pushing the boundaries of creating new technologies and moving others forward out of the lab and into the market for us to use everyday.
            </p>

            <div class="quote">
              <div class="quote__image rounded">
                <img src="../assets/dennis_experience.jpg" alt="">
                <p>
                  Prof. Dennis Frenchman
                </p>
              </div>
              <p class="quote__content">“MIT is a living and breathing engine of invention and innovation. So why not start here to hunt for technology!”</p>
            </div>

          </div>

          <div id="bytes-informing-insights">
            <h2>Transforming Data Bytes into Insights</h2>
            <p>
              The MIT Team of scouts, analysts, data scientists, designers and producers work together to create an interactive webtool that is reflective of technologies that are developing to help solve problems in real estate. The MIT scouts, analysts, data scientists, designers and producers collected data on technologies from structured and unstructured sources to gleam insights into where a technology is at in its development. From technology scouting, data collection, predictive algorithms and webtool design and development, there was a collective of curious and passionate minds that used science to create technology insights. This team worked over 2000 hours on data collection.

            </p>
            <p>
              Over 100 people have come together from MIT to create and build the Tech Tracker. Over 50 people have come from JLL to engage in an educational experience, user test the webtool and learn about technologies for real estate. This was an incredible experience built on MIT’s principles of mind and hand, where we work to educate the industry and build a better world with our partners.

            </p>

            <h3 id="mit-team">
              The MIT Team
            </h3>
            <h4>PIs & Producers</h4>
            <div class="person-list">
              <div class="person">
                <img src="../assets/profile_headshots/andrea.png" alt="">
                <div class="person-name">Dr. Andrea Chegut</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/james.jpg" alt="">
                <div class="person-name">James Scott</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/erin.jpg" alt="">
                <div class="person-name">Erin Glennon</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/dennis.jpg" alt="">
                <div class="person-name">Prof. Dennis Frenchman</div>
              </div>
            </div>

            <h4>Digital and Graphic Designers</h4>
            <div class="person-list">
              <div class="person">
                <img src="../assets/profile_headshots/isaac.jpeg" alt="">
                <div class="person-name">Isaac Chansky</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/daniel.jpg" alt="">
                <div class="person-name">Daniel Fink</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/yair.jpg" alt="">
                <div class="person-name">Yair Titelboim</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/sue.jpeg" alt="">
                <div class="person-name">234, LLC</div>
              </div>
            </div>

            <h4>Data Scientists</h4>
            <div class="person-list">
              <div class="person">
                <img src="../assets/profile_headshots/alex.jpg" alt="">
                <div class="person-name">Alex Sun</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/liang.jpg" alt="">
                <div class="person-name">Qianhui Liang</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/yuehan.jpeg" alt="">
                <div class="person-name">Yuehan Wang</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/kun.jpg" alt="">
                <div class="person-name">Kun Cheng</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/ziyu.png" alt="">
                <div class="person-name">Ziyu Ran</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/jim.jpg" alt="">
                <div class="person-name">Jim Peraino</div>
              </div>
            </div>

            <h4>Analysts</h4>
            <div class="person-list">
              <div class="person">
                <img src="../assets/profile_headshots/ruichen.jpg" alt="">
                <div class="person-name">Ruichen Ni</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/sunnie.jpg" alt="">
                <div class="person-name">Sunnie Park</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/ben.jpg" alt="">
                <div class="person-name">Ben Masselink</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/max.jpg" alt="">
                <div class="person-name">Max Betty</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/stephanie.jpg" alt="">
                <div class="person-name">Stephanie Zhou</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/minkoo.jpg" alt="">
                <div class="person-name">Minkoo Kang</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/kaini.png" alt="">
                <div class="person-name">Kaini Huang</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/kecheng.jpg" alt="">
                <div class="person-name">Kecheng Huang</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/natasha.jpg" alt="">
                <div class="person-name">Natasha Sadikin</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/jordan.jpg" alt="">
                <div class="person-name">Jordan Owen</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/rhett.jpg" alt="">
                <div class="person-name">Rhett James</div>
              </div>
              <div class="person">
                <img src="../assets/profile_headshots/sam.jpeg" alt="">
                <div class="person-name">Sam Weissman</div>
              </div>
            </div>

            <h4>Scouts</h4>
            <div class="d-flex justify-between person-list-multi">
              <div>
                Louis Liss<br>
                Zoya Puri<br>
                Hunter Fields<br>
                Keitaro Bando<br>
                Nicholas Bolland<br>
                Juan Bulnes Valdes<br>
                Yiming Cai<br>
                Mark Callahan<br>
                Sai Fang<br>
                Tim Graham<br>
                Suneeth John<br>
                Isabel Camacho<br>
                Paige Pitcher<br>
                Patrick Downey<br>
                Leon Yu<br>
              </div>
              <div class="d-flex">
                Justin Rice<br>
                Ryan Stroud<br>
                Alberto Vadia<br>
                John Weil<br>
                Ran Cao<br>
                Ye Rin Chu<br>
                David Drobins<br>
                Jeff Jamawat<br>
                Tamara Knox<br>
                Sebastian Perez<br>
                Aylea Porter<br>
                Saad Rajan<br>
                Matthew Robayna<br>
                Sean Robinson<br>
                Daniel Sibor<br>
              </div>
              <div class="d-flex">
                Weijia Song<br>
                Tia Marie Vice<br>
                Dietmar Weissmann<br>
                Ye Zhou<br>
                Abdul Hammoud<br>
                Wee Ong<br>
                Sam Howard Pepper<br>
                Yuling She<br>
                Tianyu Su<br>
                Wade Vaughn<br>
                Thomas Worth<br>
                Hong Suk Yang<br>
                Arshad Balwa<br>
                Cassie Ann Bellew<br>
                Ian Bradley<br>
              </div>
              <div class="d-flex">
                Elise Dubuque<br>
                Sarah Fayad<br>
                Fengdi Guo<br>
                Charles Hatfield<br>
                Bani Amrit Kaur<br>
                Sunghea Khil<br>
                Clay Macfarlane<br>
                Christopher Noga<br>
                William Plumb<br>
                Daniel Smicka<br>
                Alexandra Stratouly<br>
                Angela Su<br>
                Yochanan Nelson-Levy<br>
                Kok Tong Neo<br>
                Sreedath Panat<br>
              </div>
            </div>

          </div>

          <div id="mit-class">
            <h2>The MIT Course</h2>
            <p>Over time the interactive educational experience turned into an MIT seminar on technology for real estate where we would teach MIT students about the MIT laboratory environment for technologies for the built environment. The result was 11.XXX, Technological Change and Innovation in the Built Environment, an eight week course for students to discover the process that technologies go through as they move from being in the lab, to entering the market and joining our life. </p>

            <div class="image-grid">
              <div class="image-row row-1-1">
                <img src="../assets/experience/course-1.png" alt="">
                <img src="../assets/experience/course-2.png" alt="">
              </div>
              <img src="../assets/experience/course-3.png" alt="">
            </div>

            <p>
              Further, we would learn about the inventors, the current stages of the technologies development and learn about where the creators saw the technologies going next. Students would partake in a Beta Concept of the MIT Technology Tracker and scout for technologies across the MIT campus and beyond, MIT Tech Tracker analysts would then vet those findings and begin the analytics process. All past credit seeking students are listed as scouts.

            </p>
            <!-- todo image -->
          </div>

        </div>
      </section>
    </div>
    <app-footer />
  </div>
</template>
<script>
import AppHeader from './partials/AppHeader.vue';
import AppFooter from './partials/AppFooter.vue';

export default {
  name: 'Experience',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      contentNav: [
        {
          id: 'breaking-down',
          title: 'Breaking Down the Hype',
          children: [
            {
              id: 'understanding',
              title: 'Understanding Technology',
            },
            {
              id: 'catalogue',
              title: 'Creating a Catalogue of Tech',
            },
          ],
        },
        {
          id: 'hype-to-bytes',
          title: 'Turning Hype into Data Bytes',
          children: [
            {
              id: 'jll-mit',
              title: 'JLL and MIT',
            },
            {
              id: 'mit-tech-tour',
              title: 'An MIT Technology Tour',
            },
          ],
        },
        {
          id: 'bytes-informing-insights',
          title: 'Data Bytes Informing Insights',
          children: [
            {
              id: 'mit-team',
              title: 'The MIT Team',
            },
            {
              id: 'mit-class',
              title: 'The MIT Class',
            },
          ],
        },
      ],
      activeID: '',
    };
  },
  methods: {
    getActiveContent() {
      const refKeys = Object.keys(this.$refs);
      let closestVal = 1000000;
      let closestID = null;
      refKeys.forEach((refKey) => {
        if (!this.$refs[refKey]) return;
        const offsetTop = this.$refs[refKey].$el.getBoundingClientRect().top;
        if (Math.abs(150 - offsetTop) < closestVal) {
          closestVal = Math.abs(150 - offsetTop);
          closestID = this.$refs[refKey].$el.id;
        }
      });

      this.activeID = closestID;
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.$scrollTo(this.$route.query.id);
    }
    window.addEventListener('scroll', () => {
      this.getActiveContent();
    });
  },
  destroyed() {
    window.removeEventListener('scroll', () => {
      this.getActiveContent();
    });
  },
};
</script>
<style lang="postcss" scoped>
.experience {
  display: flex;
  height: 100%;
  width: auto;
  margin: 0 auto;
}
.experience__navigation {
  width: 25%;
  max-width: 240px;
  padding-top: 60px;
  padding-right: 50px;
  align-self: flex-start;
  position: sticky;
  top: var(--tt-header-height);

  ul li a {
    display: block;
    padding: 5px 0;
    line-height: 1.2;
    color: var(--tt-page-text-color);
    text-decoration: none;

    &.is-active {
      font-weight: bold;
    }
  }
  > ul > li > a {
    border-top: 1px solid var(--color-grey);
  }
  > ul > li {
    margin-bottom: 10px;
  }

  ul ul li {
    padding-left: 20px;
    font-size: var(--type-small);
  }
  button {
    text-align: left;
    margin-bottom: var(--space-1);
  }
}
.experience__content {
  flex: 1;
  height: 100%;
  overflow: scroll;
  padding-bottom: 30vh;
  padding-top: 60px;
  > * {
    max-width: 800px;
  }

  .grid {
    justify-content: space-between;
    > * {
      width: calc(50% - 20px);
    }
  }

  h1 {
    font-size: var(--type-medium);
    margin-bottom: 20px;
  }
  .subtitle {
    font-size: var(--type-lead);
  }
  h2 {
    padding-top: 10px;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 20px;
    border-top: 1px solid var(--color-grey);
  }
  h3 {
    font-size: var(--type-lead);
    margin-bottom: 20px;
  }
  h4 {
    font-size: var(--type-paragraph);
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: var(--space-3);
    line-height: 1.6;
  }

  ul li {
    margin-bottom: 8px;
  }
}
.content-block {
  margin-top: 60px;
}

.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
  .quote__image {
    width: 40%;
    text-align: center;
    font-size: 12px;
    &.rounded {
      max-width: 200px;
      img {
        border-radius: 100%;
      }
    }
  }
  .quote__content {
    padding: 60px;
    font-size: var(--type-lead);
    font-family: var(--font-mono);
    font-weight: bold;
    max-width: 32em;
  }
}

.image-two-up {
  display: flex;
  img {
    padding: 20px;
    max-width: 50%;
  }
}

.image-item {
  background-size: cover;
  background-position: center center;
  &:after {
    content: '';
    display: block;
    padding-top: 30%;
  }
}
.image-grid {
  margin: 40px -20px;
}
.image-grid img {
  padding: 20px;
}
.image-grid-row {
  display: flex;
}
.row-1-3 {
  > *:first-child {
    width: 33%;
  }
  > *:last-child {
    width: 66%;
  }
}
.row-3-1 {
  > *:first-child {
    width: 66%;
  }
  > *:last-child {
    width: 33%;
  }
}
.row-1-1 {
  > * {
    width: 50%;
  }
}
.person-list {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.person-list-multi {
  line-height: 2;
  margin-bottom: 40px;
}
.person {
  display: inline-block;
  padding: 20px;
  width: 25%;
  img {
    border-radius: 50%;
    background-color: #ccc;
    width: 75px;
    height: 75px;
  }
  .person-name {
  }
}

@media screen and (max-width: 768px) {
  .experience {
    flex-direction: column;
  }
  .experience__navigation {
    max-width: 100%;
    width: auto;
    position: static;
  }
  .technology-walkthrough {
    flex-direction: column;
  }
  .grid {
    display: block;
  }
  .experience__content .grid > * {
    width: 100%;
  }
  .person {
    width: 33%;
  }
  .person-list-multi {
    flex-wrap: wrap;
    > * {
      width: 50%;
    }
  }
}
</style>
