import Vue from 'vue';
import moment from 'moment';
import VueScrollTo from 'vue-scrollto';
import PortalVue from 'portal-vue';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import router from './router';
import store from './store';

import './styles/_utilities.css';
import './styles/_color.css';
import './styles/_typography.css';
import './styles/_grid.css';
import './styles/_elements.css';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  store.dispatch('setPageTitle', to.meta.title);
  next();
});

Vue.filter('formatDate', (v, format) => moment(v).format(format || 'MM-DD-YYYY'));
Vue.filter('capitalize', (v) => v[0].toUpperCase() + v.slice(1));
const nonTitlizableWords = ['a', 'an', 'the', 'in', 'our'];
// eslint-disable-next-line arrow-body-style
Vue.filter('titleize', (v) => {
  // eslint-disable-next-line arrow-body-style
  return v.split(' ').map((vv, i) => {
    return (i !== 0 && nonTitlizableWords.includes(vv)) ? vv : vv.charAt(0).toUpperCase() + vv.slice(1);
  }).join(' ');
});

Vue.use(VueScrollTo, {
  offset: -150,
});
Vue.use(PortalVue);
Vue.use(VueGtag, {
  config: { id: 'G-D3TSCZWNXZ' },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

store.dispatch('fetchTechnologies');
