<template>
 <div class="app-main-wrapper">
    <app-header />
    <div class="app-main guide">
      <section class="guide">
        <nav class="guide__navigation">
          <p>The MIT Tech Tracker Guide Book</p>
          <ul class="pt-2">
            <li v-for="item in contentNav" :key="item.id">
              <a class="navigation-item" :class="{'is-active': item.id === activeID}" href="#" v-scroll-to="`#${item.id}`">
                {{item.title}}
              </a>
              <ul v-if="item.children">
                <li v-for="child in item.children" :key="`child-${child.id}`">
                  <a class="navigation-item" :class="{'is-active': child.id === activeID}" href="#" v-scroll-to="`#${child.id}`">
                    {{child.title}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div class="guide__content">
          <h1>The MIT Tech Tracker Guide Book</h1>
          <p>
            This webtool is a collective understanding of the formation, development and diffusion of technology into real estate and the built environment. Our terminology and approach closely mimics that of tools that follow firms over time. However, our spanse of information is more broad as technologies have a wider experience in our lives and economies than a single firm or commercial product. In this guide, we develop a shared conceptual understanding for key definitions, methodologies and data sources for everyone to follow.
          </p>
          <div class="content-block" id="101">
            <h2>Technology 101</h2>
            <div class="grid">
              <div>
                <img src="../assets/vr-tech.png" alt="VR as a technology">
                <h3 id="what-is-a-technology">What is a technology?</h3>
                <p>
                  Our common knowledge of a technology is usually associated with gadgets or tools. However, that is a fairly limited perspective of what technologies are for humanity in general and the built environment in particular. For this webtool, technology is the science of using tools, machines, processes, uses and(or) organizational frameworks to make human life easier or more profitable. This then comes to include all facets of the built environment from how it is made, the tools and products used to make it and ultimately the product that it becomes to serve the larger purpose of housing where we live, work and play in society.
                </p>
              </div>
              <div>
                <img src="../assets/vr-prod.png" alt="VR as a product">
                <h3 id="what-is-a-commercial-product">What is a commercial product?</h3>
                <p>Commercial products are commonly confused with technologies. The reason being is because it is usually how the general public can gain access to a technology through a commercial product. For this webtool, a commercial product is a product, process or use that can be sold rather than a product process or use that cannot be sold. </p>
              </div>
            </div>
            <h3 id="why-understand-the-difference">Why understand the difference?</h3>
            <p>
              A technology can become a product, process or use that can be sold. In fact, the moment that a technology achieves being a commercial product is an important moment for a given technology in the current contemporary economy. However, it is not the only milestone that a technology reaches along its journey. Technologies play an important role in our society and economies, even long before they arrive in the marketplace as commercial products. We can gain a lot by systematically following technologies over time.
            </p>
          </div>

          <div class="content-block" id="understanding-technology-stages">
            <h2>Understanding technology stages ­— the lab, the market and our life?</h2>
            <p>
              Technologies generally follow a journey. We do not understand all of the different paths that technologies make, but we do understand some key moments. First, technologies come into existence and for some time occupy a so-called laboratory or workshop based existence. A place where technologies can get their kinks worked out. Afterwards, in the current market economy perspective they move towards a goal of being in the marketplace as commercial products. And what seems like a very linear path they move towards being in our life in various means. Of course there are some exceptions to this path where technologies tinker away in laboratories or merely dabble in the marketplace or seemingly were always a part of our life. However, it is worthy of noting each technologies journey and that path that it makes to identify exceptions in this journey.
            </p>
          </div>

          <div class="content-block" id="real-estate-as-a-technology">
            <h2>Real Estate as a Technology</h2>
            <p>
              We are not used to considering the built environment as being a part of technology culture. However, there is no authority that is saying that features of real estate and the built environment are not technologies. Buildings and cities are certainly comprised of groups of technologies and come together to make a larger technology. For example, the technologies of elevators, steel, concrete, etc. come together to enable skyscraper buildings as another technology. Technologies like elevators and skyscrapers embed in one another to enable more uses that make life easier or more profitable.
            </p>
          </div>

          <div class="content-block" id="understanding-technologies">
            <h2>Understanding Technologies</h2>
            <p>
              Developing a shared understanding of what a technology is called and what its function is currently intended to do is critical, but there is limited guidance on what we can and cannot include when discussing technologies. The tool has outlined six core areas that technology analytics has found to be essential for developing a conceptual understanding of technologies.
            </p>
          </div>

          <div class="technology-walkthrough">
            <div class="tour">
              <div class="tour-item" id="associated">
                <div class="map">
                  <img src="../assets/guide/associated.png" alt="">
                </div>
                <div class="content">
                  <h3>Associated Technologies</h3>
                  <p>Technologies do not have standard names. As a technology comes into existence, it is sometimes known as other names for some time or can switch between multiple names. This process can be confusing, which is why it is important to not only track the current primary technology name, but also its aliases. Associated names documents other known aliases.</p>
                </div>
              </div>
              <div class="tour-item" id="embedded">
                <div class="map">
                  <img src="../assets/guide/embedded.png" alt="">
                </div>
                <div class="content">
                  <h3>Embedded Technologies</h3>
                  <p>Technologies are a composite of other technologies previously developed. We document known embedded technologies used to develop the current technology.</p>
                </div>
              </div>
              <div class="tour-item" id="description">
                <div class="map">
                  <img src="../assets/guide/description.png" alt="">
                </div>
                <div class="content">
                  <h3>Description</h3>
                  <p>In essence what is the technology’s function as a product, process or use in the built environment. Early in the development of the technology, its scope for what the technology could be can be fairly broad, but as time goes on what the technology does is more concrete.</p>
                </div>
              </div>
              <div class="tour-item" id="impact">
                <div class="map">
                  <img src="../assets/guide/impact.png" alt="">
                </div>
                <div class="content">
                  <h3>Built Environment Impact</h3>
                  <p>Analysis leads to a general understanding of what the technology is for the built environment and utilizing insights of technology scouts and analysts who work across numerous technologies gives insights into what the technology can be for the built environment. In this way, this is a subjective opinion on behalf of the research team of what the technology could be for the built environment.  </p>
                </div>
              </div>
              <div class="tour-item" id="origin">
                <div class="map">
                  <img src="../assets/guide/origin.png" alt="">
                </div>
                <div class="content">
                  <h3>Origin Story</h3>
                  <p>The historical development of a technology is relevant for developing a conceptual understanding of what a technology is, what its impact on the built environment may be and the nature of other technologies that have been embedded within it for its successful development. Events capture subjective moments of relevance in a technologies journey. There is no formalized framework, but the reliance on technology analyst to capture what they deem as key moments in a technologies development.</p>
                </div>
              </div>
              <div class="tour-item" id="milestone">
                <div class="map">
                  <img src="../assets/guide/milestone.png" alt="">
                </div>
                <div class="content">
                  <h3>Milestones</h3>
                  <p>
                    Milestones can be used as exogenous moments of first key events in a technologies development. We collect the following first moments for a technologies development:
                    <ul>
                      <li>
                        <strong>Inception</strong>
                        <p>The first moment that the technology name or associated names are used.</p>
                      </li>
                      <li>
                        <strong>Conceptual Design</strong>
                        <p>The first documented and noted conceptual outline or design of the technology is made public.</p>
                      </li>
                      <li>
                        <strong>Prototype</strong>
                        <p>The first physical or digital prototype is made public.</p>
                      </li>
                      <li>
                        <strong>Academic Thesis</strong>
                        <p>The first academic thesis is published.</p>
                      </li>
                      <li>
                        <strong>Published Paper</strong>
                        <p>The first peer-reviewed journal accepted paper is published.</p>
                      </li>
                      <li>
                        <strong>Book Mention</strong>
                        <p>The first book mention whether just be as a topic or a full book dedicated to the technology.</p>
                      </li>
                      <li>
                        <strong>University Lab</strong>
                        <p>The first university lab is opened to focus on the technology or a university lab has a dedicated set of projects following the technology.</p>
                      </li>
                      <li>
                        <strong>Private R&D</strong>
                        <p>The first private firm research and development projects are publicized.</p>
                      </li>
                      <li>
                        <strong>Patent Filed</strong>
                        <p>The first patent is filed focuses on the technology itself or a contribution to making the technology, but the technology name or associated names are listed in a relevant manner.</p>
                      </li>
                      <li>
                        <strong>Patent Issued</strong>
                        <p>The first patent is issued focuses on the technology itself or a contribution to making the technology, but the technology name or associated names are listed in a relevant manner.</p>
                      </li>
                      <li>
                        <strong>First Commercial Product</strong>
                        <p>The first time a firm sells the technology in the marketplace.</p>
                      </li>
                      <li>
                        <strong>Competing Products on the Market</strong>
                        <p>The first time a second firm sells the technology in the marketplace.</p>
                      </li>
                      <li>
                        <strong>First Commercial Market Failure</strong>
                        <p>The first time we observe a commercial project failure of the technology.</p>
                      </li>
                      <li>
                        <strong>Incorporated into other Technologies</strong>
                        <p>The first time that we can observe that the technology is incorporated into other technologies, a so-called embedded technology.</p>
                      </li>
                      <li>
                        <strong>National Standards Body</strong>
                        <p>The first time that a standards body (not a government agency) agrees on a joint set of requirements for the technology. </p>
                      </li>
                      <li>
                        <strong>Governmental Regulatory Requirements</strong>
                        <p>The first time that a government requires through code or law that requirements be met in the creation of the technology.</p>
                      </li>
                      <li>
                        <strong>Word in the Dictionary</strong>
                        <p>The first time that Oxford or Webster dictionary authorities allow the technology as a word in the dictionary.</p>
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div class="tour-item" id="indicator">
                <div class="map">
                  <img src="../assets/guide/indicator.png" alt="">
                </div>
                <div class="content">
                  <h3>Technology Awareness Indicators</h3>
                  <p>
                    Technology studies aim to identify when technologies will be of general purpose use to society. Many academic studies have focused on different stages of a technology’s evolution that can start to signal this development. Our tool aims to pick up signals for various user’s of technologies across their evolution from the lag, the market and to our life.
                  </p>
                  <p>
                    We document awareness over the maximum amount of time available by each database, as well as in smaller time cohorts.
                  </p>
                  <ul>
                    <li>
                      <strong>Academic Awareness</strong>
                      <p>
                        To capture academic awareness around a topic we are turning to university DSpace portals that capture activity and mention of keywords of technologies. Given that this is MIT, we started with our very own MIT DSpace, which catalogues academic bachelor, master and PhD thesis as well as open source journal publications by faculty.
                      </p>
                    </li>
                    <li>
                      <strong>Social Media Awareness</strong>
                      <p>Social media is a nascent means of enabling public opinion in our society with numerous ways of tagging information content relationships. We use Twitter as a medium of societal awareness of technologies.</p>
                    </li>
                    <li>
                      <strong>Venture Awareness</strong>
                      <p>Initial firm development in the private sector sector is difficult to track and often not documented in the public realm. However, startup tracking databases denote what private ventures do, how many of them there are and how much funding there is.</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tour-item" id="top-tech">
                <div class="map">
                  <img src="../assets/guide/top-techs.png" alt="">
                </div>
                <div class="content">
                  <h3>Top Technologies</h3>
                  <p>We rank technologies by their current awareness in academic, social and venture circles. As data increases we will add more awareness factors to the dataset to allow a longer time series and wider experience of technologies breadth in society, then the ranking will be augmented.</p>
                  <p>MIT Alogorithm - the MIT algorithim is a weighted sum of awareness indicators weighted by their current technology stage.</p>
                </div>
              </div>
              <div class="tour-item" id="all-tech">
                <div class="map">
                  <img src="../assets/guide/all-techs.png" alt="">
                </div>
                <div class="content">
                  <h3>All Technologies</h3>
                  <p>This is the current list of all technologies in the MIT Technology Tracker, which can be sorted by numerous features of the dataset to suit various tasks and interests.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
    <app-footer />
  </div>
</template>
<script>
import AppHeader from './partials/AppHeader.vue';
import AppFooter from './partials/AppFooter.vue';

export default {
  name: 'Guide',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      contentNav: [
        {
          id: '101',
          title: 'Technology 101',
          children: [
            {
              id: 'what-is-a-technology',
              title: 'What is a technology?',
            },
            {
              id: 'what-is-a-commercial-product',
              title: 'What is a commercial product?',
            },
            {
              id: 'why-understand-the-difference',
              title: 'Why understand the difference?',
            },
          ],
        },
        {
          id: 'stages',
          title: 'Understanding Technology Stages',
        },
        {
          id: 'real-estate',
          title: 'Real  Estate as a Technology',
        },
        {
          id: 'understanding-technologies',
          title: 'Understanding Technologies',
          children: [
            {
              id: 'associated',
              title: 'Associated Technologies',
            },
            {
              id: 'embedded',
              title: 'Embedded Technologies',
            },
            {
              id: 'description',
              title: 'Description',
            },
            {
              id: 'impact',
              title: 'Built Environment Impact',
            },
            {
              id: 'origin',
              title: 'Origin Story',
            },
            {
              id: 'indicator',
              title: 'Technology Awareness Indicators',
            },
          ],
        },
      ],
      activeID: '',
    };
  },
  methods: {
    getActiveContent() {
      const refKeys = Object.keys(this.$refs);
      let closestVal = 1000000;
      let closestID = null;
      refKeys.forEach((refKey) => {
        if (!this.$refs[refKey]) return;
        const offsetTop = this.$refs[refKey].$el.getBoundingClientRect().top;
        if (Math.abs(150 - offsetTop) < closestVal) {
          closestVal = Math.abs(150 - offsetTop);
          closestID = this.$refs[refKey].$el.id;
        }
      });

      this.activeID = closestID;
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.$scrollTo(this.$route.query.id);
    }
    window.addEventListener('scroll', () => {
      this.getActiveContent();
    });
  },
  destroyed() {
    window.removeEventListener('scroll', () => {
      this.getActiveContent();
    });
  },
};
</script>
<style lang="postcss" scoped>
.guide {
  display: flex;
  height: 100%;
  width: auto;
  margin: 0 auto;
}
.guide__navigation {
  width: 25%;
  max-width: 240px;
  padding-top: 60px;
  padding-right: 50px;
  align-self: flex-start;
  position: sticky;
  top: var(--tt-header-height);

  ul li a {
    display: block;
    padding: 5px 0;
    line-height: 1.2;
    color: var(--tt-page-text-color);
    text-decoration: none;

    &.is-active {
      font-weight: bold;
    }
  }
  > ul > li > a {
    border-top: 1px solid var(--color-grey);
  }
  > ul > li {
    margin-bottom: 10px;
  }

  ul ul li {
    padding-left: 20px;
    font-size: var(--type-small);
  }
  button {
    text-align: left;
    margin-bottom: var(--space-1);
  }
}
.guide__content {
  flex: 1;
  height: 100%;
  overflow: scroll;
  padding-bottom: 30vh;
  padding-top: 60px;
  > * {
    max-width: 800px;
  }

  .grid {
    justify-content: space-between;
    > * {
      width: calc(50% - 20px);
    }
  }

  h1 {
    font-size: var(--type-medium);
    margin-bottom: 20px;
  }
  h2 {
    padding-top: 10px;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 20px;
    border-top: 1px solid var(--color-grey);
  }
  h3 {
    font-size: var(--type-paragraph);
    margin-bottom: 20px;
  }

  p {
    margin-bottom: var(--space-3);
    line-height: 1.6;
  }

  ul li {
    margin-bottom: 8px;
  }
}
.content-block {
  margin-top: 60px;
}

.technology-walkthrough {
  display: flex;
}
.map {
  margin-right: 40px;
}
.tour {
  h3 {
    margin: 0;
    font-weight: bold;
  }
  ul {
    list-style: circle;
    list-style-position: inside;
  }
}
.tour-item {
  display: flex;
  .map {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .guide {
    flex-direction: column;
  }
  .guide__navigation {
    max-width: 100%;
    width: auto;
    position: static;
  }
  .technology-walkthrough {
    flex-direction: column;
  }
  .grid {
    display: block;
  }
  .guide__content .grid > * {
    width: 100%;
  }
}
</style>
