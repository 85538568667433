<template>
  <div class="background-dots">
    <GeneratedDot :x="10" :y="0" :size="2" />
    <GeneratedDot :x="60" :y="5" :size="1" />
    <GeneratedDot :x="70" :y="15" :size="3" />
    <GeneratedDot :x="90" :y="3" :size="17" />

    <GeneratedDot :x="85" :y="60" :size="2" />
    <GeneratedDot :x="90" :y="80" :size="3" />
    <GeneratedDot :x="80" :y="85" :size="20" />
  </div>
</template>
<script>
import GeneratedDot from './GeneratedDot.vue';

export default {
  name: 'BackgroundDots',
  components: {
    GeneratedDot,
  },
};
</script>
<style lang="postcss" scoped>
.background-dots {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
}
</style>
