import { render, staticRenderFns } from "./ActionLogoBlock.vue?vue&type=template&id=23bd6579&scoped=true&"
import script from "./ActionLogoBlock.vue?vue&type=script&lang=js&"
export * from "./ActionLogoBlock.vue?vue&type=script&lang=js&"
import style0 from "./ActionLogoBlock.vue?vue&type=style&index=0&id=23bd6579&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23bd6579",
  null
  
)

export default component.exports