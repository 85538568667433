<template>
<div class="icon-logo" :class="{ collapsed, stacked }">
  <svg width="100%" height="100%" viewBox="0 0 1655 913" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g>
      <g class="group-mit">
        <path d="M103.123,228.417l12.5,-0l88.125,-214.063l0,214.063l12.813,-0l-0,-226.563l-20.938,0l-86.25,211.563l-86.25,-211.563l-20.937,0l-0,226.563l12.812,-0l0,-214.063l88.125,214.063Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <rect x="270.311" y="1.854" width="12.812" height="226.563" style="fill:#231f20;fill-rule:nonzero;"/>
        <path class="primary-letter" d="M388.123,14.042l0,214.375l12.813,-0l-0,-214.375l79.375,-0l-0,-12.188l-171.25,0l-0,12.188l79.062,-0Z" style="fill:#231f20;fill-rule:nonzero;"/>
      </g>
      <g class="group-tracker">
        <path class="primary-letter" d="M388.123,593.729l0,214.375l12.813,0l-0,-214.375l79.375,0l-0,-12.187l-171.25,-0l-0,12.187l79.062,0Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M597.186,702.792l55.625,105.312l14.375,0l-56.875,-107.187c32.5,-5.313 53.437,-20.938 53.437,-59.063c0,-50.937 -37.187,-60.312 -89.062,-60.312l-67.5,-0l-0,226.562l12.812,0l0,-104.687l54.375,-0c8.125,-0 15.625,-0 22.813,-0.625Zm-77.188,-109.688l54.063,0c42.812,0 75.937,5.313 75.937,49.375c0,43.75 -33.125,49.375 -75.937,49.375l-54.063,0l0,-98.75Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M770.936,581.542l-87.5,226.562l13.437,0l23.438,-61.562l114.375,-0l23.75,61.562l13.75,0l-87.813,-226.562l-13.437,-0Zm-45.938,152.812l52.5,-137.5l52.5,137.5l-105,0Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M1075,740.604l-13.437,0c-8.438,36.875 -38.125,59.375 -81.563,59.375c-52.187,0 -87.187,-41.562 -87.187,-105.312c-0,-64.375 35.312,-105 87.5,-105c38.75,-0 70.937,18.75 80.312,59.375l13.438,-0c-9.688,-45.938 -45.313,-71.563 -93.438,-71.563c-65.312,0 -101.562,50 -101.562,117.188c-0,67.187 35.937,117.5 100.937,117.5c49.375,-0 85.313,-25.625 95,-71.563Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M1125,707.792l0,-126.25l-12.812,-0l-0,226.562l12.812,0l0,-83.437l41.875,-41.875l89.688,125.312l15.937,0l-96.562,-134.687l91.25,-91.875l-16.25,-0l-125.938,126.25Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M1298.44,581.542l-0,226.562l141.875,0l-0,-12.187l-129.063,-0l0,-99.375l121.25,-0l0,-11.875l-121.25,-0l0,-90.938l129.063,0l-0,-12.187l-141.875,-0Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M1568.44,702.792l55.625,105.312l14.375,0l-56.875,-107.187c32.5,-5.313 53.437,-20.938 53.437,-59.063c0,-50.937 -37.187,-60.312 -89.062,-60.312l-67.5,-0l-0,226.562l12.812,0l0,-104.687l54.375,-0c8.125,-0 15.625,-0 22.813,-0.625Zm-77.188,-109.688l54.063,0c42.812,0 75.937,5.313 75.937,49.375c0,43.75 -33.125,49.375 -75.937,49.375l-54.063,0l0,-98.75Z" style="fill:#231f20;fill-rule:nonzero;"/>
      </g>
      <g class="group-tech">
        <path class="primary-letter" d="M388.123,302.167l0,214.375l12.813,-0l-0,-214.375l79.375,-0l-0,-12.188l-171.25,0l-0,12.188l79.062,-0Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M507.186,289.979l-0,226.563l141.875,-0l-0,-12.188l-129.063,0l0,-99.375l121.25,0l0,-11.875l-121.25,0l0,-90.937l129.063,-0l-0,-12.188l-141.875,0Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M869.373,449.042l-13.437,-0c-8.438,36.875 -38.125,59.375 -81.563,59.375c-52.187,-0 -87.187,-41.563 -87.187,-105.313c-0,-64.375 35.312,-105 87.5,-105c38.75,0 70.937,18.75 80.312,59.375l13.438,0c-9.688,-45.937 -45.313,-71.562 -93.438,-71.562c-65.312,-0 -101.562,50 -101.562,117.187c-0,67.188 35.937,117.5 100.937,117.5c49.375,0 85.313,-25.625 95,-71.562Z" style="fill:#231f20;fill-rule:nonzero;"/>
        <path d="M1074.69,289.979l-12.813,0l0,103.125l-142.5,0l0,-103.125l-12.812,0l-0,226.563l12.812,-0l0,-111.563l142.5,0l0,111.563l12.813,-0l-0,-226.563Z" style="fill:#231f20;fill-rule:nonzero;"/>
      </g>
      <g class="group-beta">
        <path d="M1490.03,857.482l-0,52.875l20.712,0c10.283,0 17.722,-4.23 17.722,-14.805c-0,-7.803 -3.792,-11.596 -10.356,-12.981c6.199,-2.188 8.095,-6.2 8.095,-12.034c0,-10.21 -7.585,-13.055 -17.357,-13.055l-18.816,0Zm2.99,24.067l-0,-21.368l16.263,-0c8.241,-0 13.784,2.698 13.784,10.575c0,7.949 -5.178,10.793 -13.127,10.793l-16.92,0Zm-0,26.037l-0,-23.338l17.211,-0c10.138,-0 15.024,3.282 15.024,11.377c-0,7.658 -4.813,11.961 -14.586,11.961l-17.649,-0Z" style="fill:#606060;fill-rule:nonzero;"/>
        <path d="M1538.16,857.482l-0,52.875l33.11,0l0,-2.844l-30.12,-0l-0,-23.192l28.297,-0l-0,-2.772l-28.297,0l-0,-21.222l30.12,-0l0,-2.845l-33.11,0Z" style="fill:#606060;fill-rule:nonzero;"/>
        <path d="M1592.35,860.327l0,50.03l2.99,0l0,-50.03l18.525,-0l-0,-2.845l-39.966,0l-0,2.845l18.451,-0Z" style="fill:#606060;fill-rule:nonzero;"/>
        <path d="M1629.77,857.482l-20.421,52.875l3.136,0l5.47,-14.367l26.692,-0l5.543,14.367l3.209,0l-20.493,-52.875l-3.136,0Zm-10.721,35.663l12.252,-32.089l12.252,32.089l-24.504,0Z" style="fill:#606060;fill-rule:nonzero;"/>
      </g>
    </g>
  </svg>
</div>
</template>
<script>
export default {
  name: 'AnimatedLogo',
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="postcss" scoped>
.icon-logo svg {
  height: var(--logosize);
  width: auto;
  * {
    transition: all 500ms cubic-bezier(0.68,-0.07, 0.99, 0.43);
  }
}
.collapsed .group-beta {
  opacity: 0;
}
.stacked .group-beta {
  opacity: 0;
}
.collapsed {
  .group-mit > *:not(.primary-letter) {
    opacity: 0;
    transform: translateX(20px);
  }
  .group-tech > *:not(.primary-letter) {
    opacity: 0;
    transform: translateX(-20px);
  }
  .group-tracker > *:not(.primary-letter) {
    opacity: 0;
    transform: translateX(-20px);
  }
}
.stacked {
  .group-mit {
    transform: translateY(25%);
  }
  .group-tracker {
    transform: translate(1px, -25%);
  }
}
</style>
