<template>
  <router-link :to="{ query: { techdetail: tech.id }}" class="tech-item" :class="{ proc: tech.is_proc, prod: tech.is_prod, use: tech.is_use}">
    <div>
      <h3 class="t-section mb-1">
        {{tech.name}}
      </h3>
      <p>{{ tech.description.slice(0, 140) }}{{ tech.description.length > 140 ? '…' : ''}}</p>
      <span class="tech-dot"></span>
    </div>
    <div class="actions">
      <button class="button">
        View Details
      </button>
    </div>
  </router-link>
</template>
<script>
export default {
  name: 'TechItem',
  props: {
    tech: {
      type: Object,
    },
  },
};
</script>
<style lang="postcss" scoped>
.tech-item {
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--tt-page-text-color);
  border-bottom: 1px solid var(--tt-page-text-color);
  color: var(--tt-page-text-color);
  &.use {
    --accent-color: var(--color-use);
  }
  &.prod {
    --accent-color: var(--color-product);
  }
  &.proc {
    --accent-color: var(--color-process);
  }
  &:hover {
    color: var(--color-white);
    background-color: var(--accent-color);
    .actions {
      display: block;
    }
  }
}
.actions {
  display: none;
}
.t-section {
  padding-right: 20px;
  font-weight: bold;
}
.tech-dot {
  border-radius: 50%;
  display: inline-block;
  border: 1px solid var(--accent-color);
  background-color: var(--accent-color);
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  right: 16px;
}

</style>
