<template>
  <div class="app-main-wrapper">
    <app-header />
    <div class="app-main settings">
      <h1>Settings</h1>
      <button class="button" @click="clear">Clear Cache</button>
      <p v-if="message">{{message}}</p>

      <div class="mt-3"><strong class="mt-3">Technologies & Images:</strong></div>
      <div v-for="t in technologies" :key="t.id">
        <span class="image-tech-list">
          {{ t.machine_name }} :
          <img :src="`https://ik.imagekit.io/kvsde9eyz/${t.machine_name}.jpg`" alt="">
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AppHeader from './partials/AppHeader.vue';

export default {
  name: 'About',
  components: {
    AppHeader,
  },
  data: () => ({
    message: '',
  }),
  computed: {
    ...mapState({
      technologies: (state) => state.technologies,
    }),
  },
  methods: {
    clear() {
      this.message = 'clearing...';
      this.$store.dispatch('clearCache').then(() => {
        this.message = 'cache cleared!';
        setTimeout(() => {
          this.message = '';
        }, 3000);
      });
    },
  },
};
</script>
<style lang="postcss" scoped>
.image-tech-list {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: block;
  img {
    width: 40px;
    border: 1px solid black;
  }
}
</style>
