<template>
  <div id="app">
    <portal-target name="info"></portal-target>
    <div class="filter-portal">
      <portal-target name="filters"></portal-target>
    </div>
    <div class="content">
      <div v-if="canAccess" class="app-access-wrap">
        <router-view/>
        <transition name="slide-fade-bottom">
          <div class="tech-detail-overlay" v-if="techDetailID !== null">
            <TechDetail :id="techDetailID" @close="closeTech" />
            <app-footer />
          </div>
        </transition>
      </div>
      <div v-else>
        <div class="access-prompt">
          <form class="access-prompt__content" @submit.prevent="enter">
            <logo-icon />
            <div class="field">
              <label for="accessCode" class="t-label">Access Code</label>
              <input id="accessCode" type="text" class="access-code-input" v-model="accessCode" placeholder="000000">
            </div>
            <p v-if="denied">Invalid access code, please contact us at reilabcontact@mit.edu for an access code.</p>
            <button class="button" type="submit">Enter</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LogoIcon from './components/icons/LogoIcon.vue';
import TechDetail from './views/TechDetail.vue';
import AppFooter from './views/partials/AppFooter.vue';

const ACCESS_CODES = ['WREF2021', 'SPECIAL2021', 'JLL2021', 'REI2021', '202114rei77'];

export default {
  name: 'App',
  components: {
    TechDetail,
    LogoIcon,
    AppFooter,
  },
  data: () => ({
    accessCode: '',
    canAccess: true,
    denied: false,
    animating: false,
    animateOver: false,
  }),
  computed: {
    techDetailID() {
      const id = parseInt(this.$route.query.techdetail, 10);
      return Number.isNaN(id) ? null : id;
    },
  },
  methods: {
    closeTech() {
      this.$router.push({ query: { ...this.$route.query, techdetail: undefined } });
    },
    enter() {
      if (ACCESS_CODES.includes(this.accessCode)) {
        window.localStorage.setItem('can_access', true);
        this.canAccess = true;
        this.$router.push({ name: 'home' });
      } else {
        this.denied = true;
      }
    },
  },
  watch: {
    techDetailID(val) {
      window.document.body.classList.toggle('scroll-locked', val !== null);
    },
    '$route.name': {
      handler() {
        this.$gtag.pageview(this.$route);
        this.$store.dispatch('dismissFooterOverlay');
      },
    },
  },
  mounted() {
    // window.localStorage.can_access = true;
    // const accessed = window.localStorage.getItem('can_access');
    // this.canAccess = accessed;
  },
};
</script>

<style lang="postcss">
.scroll-locked {
  overflow: hidden;
}

.app-main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 var(--space-1);
}
@media screen and (min-width: 768px){
  .app-main-wrapper {
    padding: 0 var(--space-3);
  }
}

.content {
  /* overflow-x: hidden; */
}

.tech-detail-overlay {
  z-index: var(--z-overlay3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tt-page-background-color);
  overflow: auto;
}

.icon-button {
  background: transparent;
  border: none;
  width: 4rem;
}

.base-icon {
  width: 25px;
  height: auto;
}

.access-prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.access-prompt__content {
  width: 400px;
  max-height: 400px;

  svg {
    margin: 0 auto;
    display: block;
  }
  .field > * {
    display: block;
  }
  .field input {
    font-family: monospace;
    background-color: var(--tt-page-background-color);
    color: var(--tt-page-text-color);
    padding: 18px;
    font-size: var(--fs-large);
    border: none;
    width: 100%;
    border: 1px solid var(--tt-page-text-color);
  }
  label {
    margin-bottom: 10px;
  }
  .button {
    font-size: var(--fs-large);
    margin: 20px auto;
    display: block;
    max-width: 200px;
    height: 40px;
  }
}

.filter-portal {
  top: var(--tt-header-height);
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: var(--z-overlay2);
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-left-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-left-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-left-enter, .slide-fade-left-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-50%);
  opacity: 0;
}

.slide-fade-bottom-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-bottom-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-bottom-enter, .slide-fade-bottom-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(50%);
  opacity: 0;
}
</style>
