<template>
  <section class="origin-stories">
    <h2 class="t-label-header origin-stories__header">
      Origin Story
    </h2>
    <div class="stories-vert">
      <div class="story-vert-block"
        v-for="(s, i) in stories"
        :key="i"
        :class="{'is-short': s.content.length < 40, 'is-long': s.content.length > 110 }">
        <div class="story-vert__time">
          <!-- <span v-if="s.milestone !== 'None'" class="">MILESTONE</span> -->
          <div class="date">{{s.year }}</div>
        </div>
        <div class="story-vert__content">
          <span class="milestone" v-if="s.milestone !== 'None'">{{s.milestone}}</span>
          <p>{{ s.content }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import sortBy from 'lodash/sortBy';

export default {
  name: 'OriginStory',
  props: {
    tech: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showing: 'all',
    };
  },
  computed: {
    stories() {
      let { stories } = this.tech;
      if (this.showing === 'milestones') {
        stories = stories.filter((s) => s.milestone !== 'None');
      }
      return sortBy(stories, 'year');
    },
  },
  methods: {
    filterStories(val) {
      this.showing = val;
    },
  },
};
</script>
<style lang="postcss" scoped>
.origin-stories__header {
  position: sticky;
  top: 62px;
  background-color: var(--tt-page-background-color);
  color: var(--tt-page-text-color-medium);
  z-index: 2;
  margin-bottom: 0;
}
/* .time {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.story-block + .story-block {
  border-left: 1px solid var(--med);
  padding-left: var(--space-1);
}
.story-block {
  min-width: 320px;
  margin: var(--space-1);
  margin-bottom: var(--space-2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.story-block:last-child {
  margin-right: 2rem;
}
.is-short {
  min-width: 120px;
}
.is-long {
  min-width: 400px;
}
.story-block__content {
  margin-bottom: var(--space-1);
}
.milestone-title {
  display: none;
}
.is-milestone .milestone-title {
  display: block;
} */

.stories-vert {
  position: relative;
}
.story-vert-block {
  display: flex;
  align-items: stretch;
  .story-vert__time {
    width: 100px;
    text-align: right;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .story-vert__content {
    flex: 1;
    padding-left: 32px;
  }
}
.story-vert__content {
  padding-top: 40px;
  padding-bottom: 40px;
  border-left: 1px solid var(--tt-page-text-color-light);

  .milestone {
    font-weight: bold;
  }
}

.date {
  padding-right: 50px;
  position: relative;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--tt-page-text-color-light);
    background-color: var(--tt-page-background-color);
    position: absolute;
    right: -7px;
    bottom: calc(50% - 6px);
    z-index: 1;
  }
  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--tt-page-text-color-light);
    width: 40px;
    position: absolute;
    right: 0;
    bottom: 50%;
  }
}

</style>
