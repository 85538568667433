<template>
  <router-link class="rank-item" :to="{ query: { techdetail: tech.id }}">
    <div class="grid">
      <div class="col2 total-rank">
        <div class="current">
          <strong class="">Current Filter Rank:</strong>
          {{ rank }}
        </div>
        <div class="peak" v-if="tech.peak_rank !== undefined">
          <span class="c-med">All Time Peak Rank:</span>
          {{ tech.peak_rank }}
        </div>
      </div>
      <h2 class="t-display pl-1 col4">
        <span>{{tech.name}}</span>

        <span v-if="tech.momentum" class="badge momentum" :class="`momentum--${tech.momentum.split(' ').join('-')}`">
          {{ tech.momentum }}
        </span>
      </h2>
      <p class="col2 pl-1 milestone">
        {{ tech.current_milestone }}
        <span v-if="tech.outlier === 'outlier'" class="badge outlier">
          outlier
        </span>
      </p>
    </div>
  </router-link>
</template>
<script>

export default {
  name: 'RankItem',
  props: {
    tech: {
      type: Object,
    },
    rank: {
      type: Number,
    },
  },
  data: () => ({
    expanded: false,
  }),
};
</script>
<style lang="postcss" scoped>
.rank-item {
  text-decoration: none;
  padding: var(--space-2) 0;
  border-bottom: 1px solid var(--tt-page-text-color);
  display: block;
  > .grid {
    align-items: center;
  }
}

.total-rank {
  padding: calc(var(--space-1) / 2) var(--space-1);
  svg {
    width: 1rem;
    height: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .grid {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
</style>
