<template>
  <div class="tech-detail" @keydown.esc="$emit('close')" v-if="tech">
    <header class="tech-detail__header">
      <span>Technology details</span>
      <div class="tech-details__header-actions pr-2">
        <button class="button button--circle" @click="$emit('close')">
          <x-icon />
        </button>
      </div>
    </header>

    <section class="tech-detail__main">

      <p class="title-area__sub-name">
        <span :class="`c-${tech.type}`" class="pr-1">
          {{ tech.type | capitalize}}
        </span>
      </p>
      <h1 class="title-area__name">
        {{ tech.name }}
      </h1>

      <h2 class="t-label section-label">Concept</h2>
      <div class="title-area">
        <div>
          <p class="t-label-header">Associated Technologies</p>
          <p class="details-text">{{ tech.associated.join('; ') }}</p>
          <p class="t-label-header">Embedded Technologies</p>
          <p class="details-text">{{ tech.embedded.join('; ') }}</p>
          <p class="t-label-header">Description</p>
          <p class="details-text">
            {{tech.description}}
            <br>
            <a v-if="tech.wiki_link" :href="tech.wiki_link" target="_blank" class="mr-2">
              wiki
              <external-link-icon size="14" />
            </a>
          </p>
        </div>
        <div>
          <div v-if="tech.machine_name" class="tech-img" :style="`background-image: url('https://ik.imagekit.io/kvsde9eyz/${tech.machine_name}.jpg')`" alt=""></div>
        </div>
      </div>

      <h2 class="t-label section-label">Impact</h2>
      <div class="title-area">
        <div>
          <div class="d-flex space-evenly">
            <div class="stat">
              <div class="stat-wrapper">
                <div class="t-label-header">
                  All Time Cumulative Ranking
                </div>
                <div class="stat__primary">
                  {{ rank }}
                </div>
              </div>
            </div>
            <div class="stat">
              <div class="stat-wrapper">
                <div class="t-label-header">
                  Momentum
                </div>
                <div class="stat__primary">
                  <span v-if="tech.momentum" class="badge momentum" :class="`momentum--${tech.momentum.split(' ').join('-')}`">
                    {{ tech.momentum }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p class="t-label-header">
            Built Enviroment Impact
          </p>
          <p class="details-text">
            {{tech.impact}}
          </p>
        </div>
        <div>
          <div class="d-flex space-evenly">
             <div class="stat">
              <div class="stat-wrapper">
                <div class="t-label-header">
                  Strategy
                </div>
                <div class="stat__primary">
                  {{ tech.categories.join(', ') }}
                </div>
              </div>
            </div>
            <div class="stat">
              <div class="stat-wrapper">
                <div class="t-label-header">
                  Stage
                </div>
                <div class="stat__primary">
                  {{ tech.stage | titleize }}
                </div>
              </div>
            </div>

          </div>
          <div class="d-flex space-evenly">
            <div class="stat">
              <div class="stat-wrapper">
                <div class="t-label-header">
                  Current Milestone
                </div>
                <div class="stat__primary">
                  {{ tech.current_milestone }}
                  <span v-if="tech.outlier=== 'outlier'" class="badge outlier">outlier</span>
                </div>
              </div>
            </div>
            <div class="stat">
              <div class="stat-wrapper">
                <div class="t-label-header">
                  Predicted Next Milestone
                </div>
                <div class="stat__primary">
                  {{ tech.next_milestone }} ({{ tech.next_milestone_time }}) <span v-if="tech.next_milestone_time < new Date().getFullYear()" class="late-badge">late</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="t-label section-label">Awareness</h2>
      <div class="section section--stories">
        <div class="section__sticky">
          <div class="sticky-container">
            <h2 class="t-label-header origin-stories__header">
              Awareness Factor
            </h2>
            <div class="awareness-chart">
              <line-chart v-if="awarenessChartLoaded" :chart-data="awarenessData" :options="awarenessChartOptions" />
              <span v-else>loading ... </span>
            </div>

          </div>
          <div class="section__sources">
            <button class="sources__more" @click="sourcesVisible = !sourcesVisible">
              {{ sourcesVisible ? 'Hide' : 'Show' }} Sources
            </button>
            <ul v-if="sourcesVisible">
              <li v-for="s in tech.sources" :key="s">
                <a :href="s">{{ s }}</a>
              </li>
            </ul>
          </div>
        </div>
        <origin-story :tech="tech" />
      </div>

    </section>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import { ExternalLinkIcon, XIcon } from 'vue-feather-icons';
import { mapState } from 'vuex';
import OriginStory from '../components/OriginStory.vue';
import LineChart from '../components/LineChart.vue';

export default {
  name: 'TechDetail',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    OriginStory,
    LineChart,
    ExternalLinkIcon,
    XIcon,
  },
  data() {
    return {
      awarenessChartLoaded: true,
      awarenessChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
        },
        tooltips: {
          mode: 'x-axis',
          callbacks: {
            label(tooltipItem, data) {
              const origData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].meta;
              return origData.reduce((acc, item) => {
                if (item) {
                  const title = item.type === 'twitter' ? 'Social Media' : item.type.charAt(0).toUpperCase() + item.type.slice(1);
                  acc.push(`${title}: ${item.type === 'funding' ? `$${item[String(tooltipItem.xLabel)].toFixed(1).toLocaleString('en-US')}M` : item[String(tooltipItem.xLabel)].toLocaleString('en-US')}`);
                }
                return acc;
              }, []).join('; ');
            },
          },
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              max: 100,
              min: 0,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          y: {
            title: {
              display: true,
              text: 'Awareness Score',
            },
          },
          x: {
            type: 'time',
            time: {
              // Luxon format string
              tooltipFormat: 'YYYY',
            },
            title: {
              display: true,
              text: 'Year',
            },
          },
        },
      },
      sourcesVisible: false,
    };
  },
  computed: {
    ...mapState({
      technologies: 'technologies',
    }),
    awarenessData() {
      if (!this.tech) return {};
      return this.processChartData(this.tech.awareness);
    },
    milestones() {
      let ms = [];
      if (this.tech.stories) {
        ms = this.tech.stories.filter((s) => s.milestone !== 'None').map((m) => ({
          ...m,
        }));
      }
      return orderBy(ms, 'year', 'desc');
    },
    tech() {
      return this.$store.getters.getTechById(this.id);
    },
    rank() {
      if (!this.tech) return undefined;
      // all techs, all data, comprehensive timeframe
      const rankedTechs = orderBy(this.technologies, 'score_all_time', 'desc');
      const index = rankedTechs.findIndex((t) => t.id === this.tech.id);
      return index !== -1 ? index + 1 : ' - ';
    },
  },
  methods: {
    processChartData(data) {
      const dataFilter = (kv) => !['id', 'tech_name', 'type'].includes(kv[0]) && parseInt(kv[0], 10) > 2005;
      const dataByKeys = {
        smoothed: {},
        original: {},
      };
      data.smoothed.forEach((item) => {
        dataByKeys.smoothed[item.type] = item;
      });
      data.original.forEach((item) => {
        dataByKeys.original[item.type] = item;
      });
      return {
        // Get all years from first dataset
        labels: Object.entries(data.smoothed[0]).filter(dataFilter).map((kv) => parseInt(kv[0], 10)),
        datasets: [
          {
            fill: true,
            spanGaps: true,
            pointRadius: 1,
            pointHitRadius: 30,
            pointHoverRadius: 8,
            label: dataByKeys.smoothed.social.type,
            backgroundColor: '#782aff',
            data: Object.entries(dataByKeys.smoothed.social).filter(dataFilter).map((kv) => ({
              x: parseInt(kv[0], 10),
              y: kv[1],
              meta: [dataByKeys.original.twitter, dataByKeys.original.news],
            })),
          },
          {
            fill: true,
            spanGaps: true,
            pointRadius: 1,
            pointHitRadius: 30,
            pointHoverRadius: 8,
            label: dataByKeys.smoothed.venture.type,
            backgroundColor: '#009761',
            data: Object.entries(dataByKeys.smoothed.venture).filter(dataFilter).map((kv) => ({
              x: parseInt(kv[0], 10),
              y: kv[1],
              meta: [dataByKeys.original.funding, dataByKeys.original.company],
            })),
          },
          {
            fill: true,
            spanGaps: true,
            pointRadius: 1,
            pointHitRadius: 30,
            pointHoverRadius: 8,
            label: dataByKeys.smoothed.academic.type,
            backgroundColor: '#faab1a',
            data: Object.entries(data.smoothed[0]).filter(dataFilter).map((kv) => ({
              x: parseInt(kv[0], 10),
              y: kv[1],
              meta: [dataByKeys.original.patent, dataByKeys.original.paper],
            })),
          },
        ],
      };
    },
  },
  async created() {
    // const data = await apiService.fetchChartDataForTechnology(this.id);
    // this.awarenessData = data.awareness_data;
    // this.processChartData(data.awareness_data);
    // this.awarenessChartLoaded = true;
  },
};
</script>
<style lang="postcss" scoped>
.tech-detail {
  position: relative;
  min-height: 100%;
  margin: 0 0 0 var(--space-1);
  @media screen and (min-width: 768px) {
    margin: 0 var(--space-2);

  }
}
.section-label {
  margin-top: 60px;
}
.title-area {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  > * {
    width: calc(50% - 16px);
  }
}
.title-area__sub-name {
  font-weight: bold;
  margin-bottom: 15px;
}
.title-area__name {
  font-size: 72px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 24px;
}

.details-text {
  margin-bottom: 15px;
  line-height: 1.4;
}

.section__sticky {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sticky-container {
    position: sticky;
    top: 45px;
  }
}
.section__sources {
  a {
    font-size: var(--type-small);
    line-height: 1.2;
    margin-bottom: 8px;
    display: inline-block;
  }
}

.section--stories {
  display: flex;
  justify-content: space-between;
  > * {
    width: calc(50% - 16px);
  }
}

.stat {
  display: flex;
  justify-content: flex-start;
}
.stat__primary {
  font-size: var(--type-medium);
  line-height: 1.1;
  .badge {
    margin: 0;
  }
}
.stat__secondary {
  font-size: var(--type-small);
  padding: 4px 0;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--tt-page-text-color-medium)
}

.tech-detail__header {
  background-color: var(--tt-page-background-color);
  border-bottom: 1px solid var(--tt-page-text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-3);
  padding: var(--space-1) 0;
  font-size: var(--fs-base);
  position: sticky;
  top: 0;
  z-index: 2;
}
.tech-img {
  max-width: 800px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center left;
}
.tech-details-lists li {
  margin-bottom: 0.25em;
}
.button-close {
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.tech-details__header-actions {
  display: flex;
}

.late-badge {
  border-radius: 50px;
  background-color: var(--color-red);
  font-size: 10px;
  letter-spacing: 1px;
  padding: 3px 10px 0px;
  line-height: 1;
  color: var(--color-white);
}

.space-evenly {
  justify-content: space-between;
  > * {
    width: calc(50% - 20px);

  }
}
@media screen and (max-width: 768px) {
  .title-area {
    display: block;
    > * {
      width: 100%;
    }
  }
  .tech-detail {
    margin: 0;
    padding: 0 var(--space-2);
  }
  .section--stories {
    display: block;
    > * {
      width: 100%;
    }
  }
}
</style>
